import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export const validateCurrency = (
  value: InputValueType,
  translations: Translation[],
) => {
  if (!value) {
    return '';
  }

  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    return translate(translations, 'VALIDATION.INVALID_CURRENCY_FORMAT');
  }

  const parsedNumber = parseFloat(value);

  if (parsedNumber < 0) {
    return translate(translations, 'VALIDATION.INVALID_CURRENCY_FORMAT');
  }

  return '';
};
