import { Translation } from '../../domain/Translation';
import { validateRequired } from './validators/required/required.validator';
import {
  MinStringLengthValidator,
  validateMinStringLength,
} from './validators/minStringLength/minStringLength.validator';
import { validateEmail } from './validators/email/email.validator';
import { InputValue, InputValueType } from '../../app/hooks/useForm/useForm';
import {
  MatchingPasswordsValidator,
  validateMatchingPasswords,
} from './validators/matchingPasswords/matchingPasswords.validator';
import {
  MaxStringLengthValidator,
  validateMaxStringLength,
} from './validators/maxStringLength/maxStringLength.validator';
import { validatePhone } from './validators/phone/phone.validator';
import { validateEnabled } from './validators/enabled/enabled.validator';
import { validateCurrency } from './validators/currency/currency.validator';
import { validateNumber } from './validators/number/number.validator';
import { MinValidator, validateMin } from './validators/min/min.validator';
import { MaxValidator, validateMax } from './validators/max/max.validator';
import {
  MatchingValueValidator,
  validateMatchingValue,
} from './validators/matchingValue/matchingValue.validator';
import { validateIban } from './validators/iban/iban.validator';
import { validateBic } from './validators/bic/bic.validator';

export enum InputValidationType {
  'required' = 'required',
  'minStringLength' = 'minStringLength',
  'maxStringLength' = 'maxStringLength',
  'email' = 'email',
  'matchingPasswords' = 'matchingPasswords',
  'phone' = 'phone',
  'enabled' = 'enabled',
  'currency' = 'currency',
  'number' = 'number',
  'min' = 'min',
  'max' = 'max',
  'matchingValue' = 'matchingValue',
  'iban' = 'iban',
  'bic' = 'bic',
}

export type InputValidation =
  | {
      type: InputValidationType.required;
    }
  | (MinStringLengthValidator & { type: InputValidationType.minStringLength })
  | (MaxStringLengthValidator & { type: InputValidationType.maxStringLength })
  | { type: InputValidationType.email }
  | (MatchingPasswordsValidator & {
      type: InputValidationType.matchingPasswords;
    })
  | {
      type: InputValidationType.phone;
    }
  | {
      type: InputValidationType.enabled;
    }
  | {
      type: InputValidationType.currency;
    }
  | {
      type: InputValidationType.number;
    }
  | (MinValidator & {
      type: InputValidationType.min;
    })
  | (MaxValidator & {
      type: InputValidationType.max;
    })
  | (MatchingValueValidator & {
      type: InputValidationType.matchingValue;
    })
  | { type: InputValidationType.iban }
  | { type: InputValidationType.bic };

export const validateInput = (
  value: InputValueType,
  values: InputValue[],
  rules: InputValidation[],
  translations: Translation[],
): string[] => {
  return rules
    .map((rule) => {
      return validateSingleRule(value ?? '', values, rule, translations);
    })
    .filter((validationError) => !!validationError);
};

export const validateSingleRule = (
  value: InputValueType,
  values: InputValue[],
  inputValidation: InputValidation,
  translations: Translation[],
) => {
  switch (inputValidation.type) {
    case InputValidationType.minStringLength:
      return validateMinStringLength(value, inputValidation, translations);
    case InputValidationType.maxStringLength:
      return validateMaxStringLength(value, inputValidation, translations);
    case InputValidationType.required:
      return validateRequired(value, translations);
    case InputValidationType.email:
      return validateEmail(value, translations);
    case InputValidationType.matchingPasswords:
      return validateMatchingPasswords(
        value,
        values,
        inputValidation,
        translations,
      );
    case InputValidationType.phone:
      return validatePhone(value, translations);
    case InputValidationType.enabled:
      return validateEnabled(value, translations);
    case InputValidationType.currency:
      return validateCurrency(value, translations);
    case InputValidationType.number:
      return validateNumber(value, translations);
    case InputValidationType.min:
      return validateMin(value, inputValidation, translations);
    case InputValidationType.max:
      return validateMax(value, inputValidation, translations);
    case InputValidationType.matchingValue:
      return validateMatchingValue(value, inputValidation, translations);
    case InputValidationType.iban:
      return validateIban(value, translations);
    case InputValidationType.bic:
      return validateBic(value, translations);
    default:
      return '';
  }
};
