import React from 'react';
import styles from './MyGarage.module.scss';
import { Box, Radio, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import TextInput from '../../../common/Input/TextInput/TextInput';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from '../../../common/Button/Button';
import { routes } from '../../../../config/routes/routes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import cx from 'classnames';
import refreshIcon from '../../../../assets/icons/refresh.svg';
import deleteIcon from '../../../../assets/icons/delete.svg';
import chevronRightIcon from '../../../../assets/icons/chevron-right.svg';
import saveIcon from '../../../../assets/icons/save.svg';
import infoIcon from '../../../../assets/icons/info.svg';
import leftIcon from '../../../../assets/icons/chevron-left.svg';
import { currentUserState } from '../../../../atoms/user/user.atom';
import {
  useVehiclesByIdsQuery,
  useUserVehiclesQuery,
} from '../../../../api/vehicle/queries';
import dayjs from 'dayjs';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../../utils/localStorage/localStorage';
import {
  savedVehicleIdsState,
  selectedVehicleIdState,
} from '../../../../atoms/vehicle/vehicle.atom';
import { useDeleteUserVehicleMutation } from '../../../../api/vehicle/mutations';
import { SelectedVehicle } from '../../../../domain/Vehicle';
import { toast } from 'sonner';

type Props = {
  listContainerClassName?: string;
  onTitleClick?: () => void;
  onSelect?: () => void;
};

const MyGarage = ({
  listContainerClassName,
  onTitleClick,
  onSelect,
}: Props) => {
  const navigate = useNavigate();
  const translations = useRecoilValue(translationsState);
  const [selectedVehicleId, setSelectedVehicleId] = useRecoilState(
    selectedVehicleIdState,
  );
  const [savedVehicleIds, setSavedVehicleIds] =
    useRecoilState(savedVehicleIdsState);
  const user = useRecoilValue(currentUserState);

  const { data: userVehicles } = useUserVehiclesQuery(!!user);
  const { data: savedVehicles } = useVehiclesByIdsQuery(
    savedVehicleIds,
    !user && savedVehicleIds.length > 0,
  );
  const vehiclesToShow = user ? userVehicles : savedVehicles;

  const { mutate: deleteUserVehicleMutation } = useDeleteUserVehicleMutation({
    onSuccess: () =>
      toast.success(translate(translations, 'MY_GARAGE.DELETE_SUCCESS')),
    onError: (error) =>
      toast.error(
        translate(
          translations,
          error?.response?.data?.message ?? 'Unknown error',
        ),
      ),
  });

  const handleDelete = (id: number) => {
    if (!!user) {
      deleteUserVehicleMutation(id);
    }

    const updatedVehicleIds = savedVehicleIds.filter(
      (savedId) => savedId !== id,
    );
    setSavedVehicleIds(updatedVehicleIds);
    setLocalStorageValue(LocalStorageKey.savedVehicles, updatedVehicleIds);

    if (selectedVehicleId != id) {
      return;
    }

    setSelectedVehicleId(null);
    setLocalStorageValue(LocalStorageKey.selectedVehicle, null);
  };

  const handleSelect = (vehicle: SelectedVehicle) => {
    setSelectedVehicleId(vehicle.id);
    setLocalStorageValue(LocalStorageKey.selectedVehicle, vehicle.id);

    navigate(
      routes.vehicleCatalog
        .replace(':brand', vehicle.brand.name.toLowerCase() ?? '')
        .replace(':vehicleModel', vehicle.vehicleModel.name.toLowerCase() ?? '')
        .replace(':vehicle', vehicle.name.toLowerCase() ?? '')
        .replace(/ /g, '-'),
    );

    onSelect?.();
  };

  return (
    <>
      <Box className={styles.infoContainer}>
        <Box
          className={cx(styles.titleContainer, {
            [styles.button]: !!onTitleClick,
          })}
          onClick={onTitleClick}
        >
          {onTitleClick && <ReactSVG src={leftIcon} />}
          <Typography className={styles.title}>
            {translate(translations, 'MY_GARAGE.TITLE')}
          </Typography>
        </Box>

        <Box className={styles.infoPopout}>
          <ReactSVG src={infoIcon} />
          <Typography className={styles.infoText}>
            {translate(translations, 'MY_GARAGE.INFO_TEXT')}
          </Typography>
        </Box>
      </Box>

      <Box className={cx(styles.carListContainer, listContainerClassName)}>
        {vehiclesToShow.map((car) => (
          <Box key={car.id} className={styles.car}>
            <Radio
              checked={selectedVehicleId === car.id}
              onClick={() => handleSelect(car)}
            />
            <Box className={styles.carInfo}>
              <Typography className={styles.carName}>
                {`${car.brand.name} ${car.name}`}
              </Typography>
              <Typography className={styles.carDescription}>
                {/* eslint-disable-next-line max-len */}
                {`${car.vehicleModel.name} (${car.kiloWatts ?? 'N/A'} kw / ${car.horsePower ?? 'N/A'} hp) (${dayjs(car.dateFrom).format('YYYY.MM.DD')} - ${dayjs(car.dateTo).format('YYYY.MM.DD')})`}
              </Typography>
            </Box>
            <Box className={styles.actionsContainer}>
              <ReactSVG
                onClick={() => handleDelete(car.id)}
                className={styles.deleteBtn}
                src={deleteIcon}
              />
              <ReactSVG
                onClick={() => handleSelect(car)}
                className={styles.goBtn}
                src={chevronRightIcon}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box className={styles.vinContainer}>
        <TextInput
          className={styles.vinInput}
          label={translate(translations, 'MY_GARAGE.VIN_INPUT_LABEL')}
        />
        <Button variant={ButtonVariant.secondary}>
          <ReactSVG src={refreshIcon} />
        </Button>
        <Button>
          <ReactSVG src={saveIcon} />
        </Button>
      </Box>

      <Button
        wrapperClassName={styles.btn}
        onClick={() => navigate(routes.profile.vehicles)}
      >
        {translate(translations, 'MY_GARAGE.BUTTON')}
      </Button>
    </>
  );
};

export default MyGarage;
