import { InputValueType } from '../../../../app/hooks/useForm/useForm';
import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';

export const validateIban = (
  value: InputValueType,
  translations: Translation[],
): string => {
  const iban = value.trim().toUpperCase() as string;

  if (iban.length < 15 || iban.length > 34) {
    return translate(translations, 'VALIDATION.INVALID_IBAN_LENGTH');
  }

  if (!/^[A-Z]{2}\d{2}[A-Z0-9]{4,30}$/.test(iban)) {
    return translate(translations, 'VALIDATION.INVALID_IBAN_FORMAT');
  }

  const rearranged = iban.slice(4) + iban.slice(0, 4);

  const converted = rearranged.replace(/[A-Z]/g, (char) =>
    (char.charCodeAt(0) - 55).toString(),
  );

  let remainder = converted;
  while (remainder.length > 2) {
    const block = remainder.slice(0, 9);
    remainder = (parseInt(block, 10) % 97).toString() + remainder.slice(9);
  }

  if (parseInt(remainder, 10) % 97 !== 1) {
    return translate(translations, 'VALIDATION.INVALID_IBAN');
  }

  return '';
};
