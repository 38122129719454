import React from 'react';

export type UrlParamInputProps = {
  name?: string;
  paramName?: string;
};

export const UrlParamInput = ({}: UrlParamInputProps) => {
  return <></>;
};

export default UrlParamInput;
