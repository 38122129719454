import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../config/api/apiRoutes';
import axios from '../../libraries/axios/axios';
import { queryKeys } from '../keys';
import { StaticPage, StaticPageType } from '../../domain/StaticPage';
import { useSetRecoilState } from 'recoil';
import { staticPagesState } from '../../atoms/static-page/static-pages.atom';

export const useStaticPagesQuery = () => {
  const setStaticPages = useSetRecoilState(staticPagesState);

  const fetchStaticPages = async (): Promise<StaticPage[]> => {
    const { data } = await axios.get(apiRoutes.staticPages.getAll);
    setStaticPages(data);

    return data;
  };

  return useQuery({
    queryKey: queryKeys.staticPages.all,
    queryFn: fetchStaticPages,
    initialData: [],
  });
};

export const useStaticPageNameQuery = (name: StaticPageType) => {
  const fetchStaticPage = async (name: StaticPageType): Promise<StaticPage> => {
    const { data } = await axios.get(
      apiRoutes.staticPages.get.replace(':name', name),
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.staticPages.byName(name),
    queryFn: () => fetchStaticPage(name),
  });
};
