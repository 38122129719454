import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export type MaxValidator = {
  length: number;
};

export const validateMax = (
  value: InputValueType,
  rule: MaxValidator,
  translations: Translation[],
) => {
  const valueNumber = Number(value);

  if (valueNumber > rule.length) {
    return translate(translations, 'VALIDATION.MAX').replace(
      ':length',
      rule.length.toString(),
    );
  }

  return '';
};
