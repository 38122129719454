import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TopNavigation from './TopNavigation/TopNavigation';
import Footer from '../../components/Footer/Footer';
import Subscribe from '../../components/Subscribe/Subscribe';
import Manufacturers from '../../components/Manufacturers/Manufacturers';
import CTA from '../../components/CTA/CTA';
import Community from '../../components/Community/Community';
import FeaturedBlogs from '../../components/FeaturedBlogs/FeaturedBlogs';
import AnnouncementBar from '../../components/AnnouncementBar/AnnouncementBar';

export const PublicLayout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  return (
    <>
      <AnnouncementBar />
      <TopNavigation />
      <Outlet />
      <Manufacturers />
      <CTA />
      <Community />
      <FeaturedBlogs />
      <Subscribe />
      <Footer />
    </>
  );
};

export default PublicLayout;
