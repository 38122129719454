import React, { useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styles from './MobileDrawer.module.scss';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import LoginModal from '../../../../components/LoginModal/LoginModal';
import CategoryItem, {
  Category,
} from '../../../../components/PartCategoryDropdown/CategoryItem/CategoryItem';
import SubCategoryList from '../../../../components/PartCategoryDropdown/SubCategoryList/SubCategoryList';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownItem from '../../../../components/PartCategoryDropdown/DropdownItem/DropdownItem';
import { useRecoilState, useRecoilValue } from 'recoil';
import { translationsState } from '../../../../../atoms/translation/translation.atom';
import { translate } from '../../../../../utils/translation/translation';
import hamburgerMenuIcon from '../../../../../assets/icons/hamburger-menu.svg';
import carIcon from '../../../../../assets/icons/car.svg';
import truckIcon from '../../../../../assets/icons/truck.svg';
import brakeIcon from '../../../../../assets/icons/brakes.svg';
import closeIcon from '../../../../../assets/icons/close.svg';
import userIcon from '../../../../../assets/icons/user.svg';
import garageIcon from '../../../../../assets/icons/garage.svg';
import MyGarage from '../../../../components/MyGarageMenu/MyGarage/MyGarage';
import { currentUserState } from '../../../../../atoms/user/user.atom';
import { routes } from '../../../../../config/routes/routes';
import { loginModalState } from '../../../../../atoms/auth/auth.atom';

const categories: Category[] = [
  {
    id: 1,
    name: 'Car parts',
    icon: carIcon,
    subCategories: [
      {
        id: 2,
        name: 'Engine oil',
        icon: brakeIcon,
        items: [
          { name: 'Automatic transmissions fluids' },
          { name: 'Engine coolant' },
          { name: 'Engine oil' },
          { name: 'Gearbox oil and transmissions fluid' },
        ],
      },
      {
        id: 3,
        name: 'Tyres',
        icon: brakeIcon,
        items: [
          { name: 'Automatic transmissions fluids' },
          { name: 'Engine coolant' },
          { name: 'Engine oil' },
          { name: 'Gearbox oil and transmissions fluid' },
        ],
      },
      { id: 4, name: 'Body', icon: brakeIcon },
    ],
  },
  {
    id: 5,
    name: 'Truck parts',
    icon: truckIcon,
  },
];

const MobileDrawer = () => {
  const translations = useRecoilValue(translationsState);
  const currentUser = useRecoilValue(currentUserState);
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] =
    useRecoilState(loginModalState);
  const [isMyGarageOpen, setIsMyGarageOpen] = useState(false);
  const [openCategoryId, setOpenCategoryId] = useState<number>();
  const [openSubcategoryId, setOpenSubcategoryId] = useState<number>();

  const openedSubCategory = useMemo(() => {
    if (!openSubcategoryId || !openSubcategoryId) {
      return undefined;
    }

    const category = categories.find(
      (category) => category.id === openCategoryId,
    );
    const subCategory = category?.subCategories?.find(
      (subCategory) => subCategory.id === openSubcategoryId,
    );

    return subCategory;
  }, [openCategoryId, openSubcategoryId]);

  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location, isLoginModalOpen]);

  const handleCategoryClick = (id: number) => {
    openCategoryId === id
      ? setOpenCategoryId(undefined)
      : setOpenCategoryId(id);
  };

  const handleSubCategoryClick = (id: number) => {
    setOpenSubcategoryId(id);
  };

  return (
    <>
      <ReactSVG
        src={hamburgerMenuIcon}
        className={styles.hamburgerIcon}
        onClick={() => setIsDrawerOpen(true)}
      />

      <Drawer
        className={styles.drawer}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box className={styles.closeBtnContainer}>
          <IconButton
            className={styles.closeBtn}
            disableRipple
            onClick={() => setIsDrawerOpen(false)}
          >
            <ReactSVG src={closeIcon} />
          </IconButton>
        </Box>
        <Box className={styles.content}>
          {!openedSubCategory && !isMyGarageOpen && (
            <>
              <DropdownItem
                onClick={() =>
                  currentUser?.identity
                    ? navigate(routes.profile.vehicles)
                    : setIsLoginModalOpen(true)
                }
                name={
                  currentUser?.profile.firstName ??
                  translate(translations, 'MOBILE_DRAWER.SIGN_IN')
                }
                icon={userIcon}
                showChevron={false}
              />
              <DropdownItem
                onClick={() => setIsMyGarageOpen(true)}
                name={translate(translations, 'MOBILE_DRAWER.MY_GARAGE')}
                description={translate(
                  translations,
                  'MOBILE_DRAWER.MY_GARAGE_SUBTITLE',
                )}
                icon={
                  <Box className={styles.garageIcon}>
                    <ReactSVG src={garageIcon} />
                    <Typography className={styles.carNumber}>03</Typography>
                  </Box>
                }
                horizontalChevron
              />
              {categories.map((category) => (
                <CategoryItem
                  key={category.id}
                  category={category}
                  onClick={() => handleCategoryClick(category.id)}
                  isOpen={openCategoryId === category.id}
                  onSubCategoryClick={handleSubCategoryClick}
                />
              ))}
            </>
          )}
          {openedSubCategory && (
            <SubCategoryList
              onTitleClick={() => setOpenSubcategoryId(undefined)}
              subCategory={openedSubCategory}
            />
          )}
          {isMyGarageOpen && (
            <Box className={styles.myGarageContainer}>
              <MyGarage
                listContainerClassName={styles.garageListContainer}
                onTitleClick={() => setIsMyGarageOpen(false)}
              />
            </Box>
          )}
        </Box>
      </Drawer>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default MobileDrawer;
