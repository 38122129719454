import { InputValueType } from '../../../../app/hooks/useForm/useForm';
import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';

export const validateBic = (
  value: InputValueType,
  translations: Translation[],
): string => {
  const bic = value.trim().toUpperCase() as string;

  if (bic.length !== 8 && bic.length !== 11) {
    return translate(translations, 'VALIDATION.INVALID_BIC_LENGTH');
  }

  const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
  if (!bicRegex.test(bic)) {
    return translate(translations, 'VALIDATION.INVALID_BIC');
  }

  return '';
};
