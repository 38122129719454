import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { HttpError } from '../../types/Api';
import { SubmitValues } from '../../app/hooks/useForm/useForm';
import axios from '../../libraries/axios/axios';
import { apiRoutes } from '../../config/api/apiRoutes';
import { Profile } from '../../domain/Profile';

type UpdateProfileRequest = { id: number | string } & SubmitValues;

export const useUpdateUserProfileMutation = (
  options?: UseMutationOptions<Profile, HttpError, UpdateProfileRequest>,
) => {
  const patchUserProfile = async ({
    id,
    ...values
  }: UpdateProfileRequest): Promise<Profile> => {
    const { data } = await axios.patch(
      apiRoutes.profile.updateUserProfile.replace(':id', id.toString()),
      values,
    );
    return data;
  };

  return useMutation<Profile, HttpError, UpdateProfileRequest>({
    ...options,
    mutationFn: patchUserProfile,
  });
};
