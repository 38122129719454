import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { LayoutFactory, PageLayoutType } from '../../layout/LayoutFactory';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { authTokenState, roleAccessState } from '../../../atoms/auth/auth.atom';
import { routes } from '../../../config/routes/routes';
import LazyLoad from './LazyLoading/LazyLoading';
import NotFoundPage from '../../pages/shared/error/NotFoundPage/NotFoundPage';
import { useMutation } from '@tanstack/react-query';
import axios, { SuccessResponse } from '../../../libraries/axios/axios';
import { MappedRoleAccess } from '../../../domain/Role';
import { DefaultError } from '@tanstack/query-core/build/modern/index';
import { apiRoutes } from '../../../config/api/apiRoutes';
import { useStaticPagesQuery } from '../../../api/static-pages/queries';
import { currentCartItemsState } from '../../../atoms/cart/cart.atom';
import { useCreateOrUpdateUserBasketMutation } from '../../../api/basket/mutations';
import {
  getLocalStorageValue,
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../utils/localStorage/localStorage';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable new-cap */
const Homepage = LazyLoad(() => import('../../pages/Homepage/Homepage'));
const CatalogPage = LazyLoad(
  () => import('../../pages/CatalogPage/CatalogPage'),
);
const ProductsPage = LazyLoad(
  () => import('../../pages/ProductsPage/ProductsPage'),
);
const ProductPage = LazyLoad(
  () => import('../../pages/ProductPage/ProductPage'),
);
const CheckoutPage = LazyLoad(
  () => import('../../pages/CheckoutPage/CheckoutPage'),
);
const CheckoutOrderPage = LazyLoad(
  () => import('../../pages/CheckoutOrderPage/CheckoutOrderPage'),
);
const ProfileAddressPage = LazyLoad(
  () => import('../../pages/profile/AddressPage/AddressPage'),
);
const ProfileBankDetailsPage = LazyLoad(
  () => import('../../pages/profile/BankDetailsPage/BankDetailsPage'),
);
const ProfileFavoritesPage = LazyLoad(
  () => import('../../pages/profile/FavoritesPage/FavoritesPage'),
);
const ProfileOrdersPage = LazyLoad(
  () => import('../../pages/profile/OrdersPage/OrdersPage'),
);
const ProfileSettingsPage = LazyLoad(
  () => import('../../pages/profile/SettingsPage/SettingsPage'),
);
const ProfileVehiclesPage = LazyLoad(
  () => import('../../pages/profile/VehiclesPage/VehiclesPage'),
);
const ArticleCategoriesPage = LazyLoad(
  () =>
    import('../../pages/articles/ArticleCategoriesPage/ArticleCategoriesPage'),
);
const ArticleCategoryPage = LazyLoad(
  () => import('../../pages/articles/ArticleCategoryPage/ArticleCategoryPage'),
);
const ArticlePage = LazyLoad(
  () => import('../../pages/articles/ArticlePage/ArticlePage'),
);
const StaticPage = LazyLoad(() => import('../../pages/StaticPage/StaticPage'));
/* eslint-enable new-cap */

export const RouteProvider = () => {
  const authStateValue = useRecoilValue(authTokenState);
  const setRoleAccessStateValue = useSetRecoilState(roleAccessState);
  const [cartItems] = useRecoilState(currentCartItemsState);

  const { mutate: getMappedRoleAccess } = useMutation<
    SuccessResponse<MappedRoleAccess[]>,
    DefaultError
  >({
    mutationFn: () => axios.get(apiRoutes.roles.mappedAccess),
    onSuccess: (response) => {
      setRoleAccessStateValue(response.data);
    },
  });

  const getToken = () => {
    const existingToken = getLocalStorageValue<string>(
      LocalStorageKey.basketId,
    );

    if (existingToken) {
      return existingToken;
    }

    const token = uuidv4();
    setLocalStorageValue(LocalStorageKey.basketId, token);

    return token;
  };

  const { mutate: createOrUpdateBasket } =
    useCreateOrUpdateUserBasketMutation();

  useEffect(() => {
    if (!authStateValue) {
      return;
    }

    if (cartItems.length > 0) {
      createOrUpdateBasket({
        token: getToken(),
        basketItems: cartItems.map((cartItem) => ({
          product: cartItem.productId,
          quantity: cartItem.quantity,
        })),
      });
    }
  }, [authStateValue, cartItems]);

  const { data: staticPageArr } = useStaticPagesQuery();

  useEffect(() => {
    if (authStateValue) {
      getMappedRoleAccess();
    }
  }, [authStateValue]);

  const publicPages = useMemo(() => {
    return (
      <>
        <Route path={routes.catalog} element={<CatalogPage />} />
        <Route path={routes.brandCatalog} element={<CatalogPage />} />
        <Route path={routes.vehicleCatalog} element={<CatalogPage />} />
        <Route path={routes.products} element={<ProductsPage />} />
        <Route path={routes.product} element={<ProductPage />} />
        <Route
          path={routes.articles.index}
          element={<Navigate to={routes.articles.categories} replace />}
        />
        <Route
          path={routes.articles.categories}
          element={<ArticleCategoriesPage />}
        />
        <Route
          path={routes.articles.category}
          element={<ArticleCategoryPage />}
        />
        <Route path={routes.articles.article} element={<ArticlePage />} />
        <Route path={routes.articles.article} />
        <Route path={routes.homepage} element={<Homepage />} />
        <Route path="*" element={<NotFoundPage />} />
      </>
    );
  }, [authStateValue]);

  const basketPages = useMemo(() => {
    return (
      <>
        <Route path={routes.checkout} element={<CheckoutPage />} />
        <Route path={routes.checkoutOrder} element={<CheckoutOrderPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </>
    );
  }, [authStateValue]);

  const profilePages = useMemo(() => {
    return (
      <>
        <Route path={routes.profile.address} element={<ProfileAddressPage />} />
        <Route
          path={routes.profile.bankDetails}
          element={<ProfileBankDetailsPage />}
        />
        <Route
          path={routes.profile.favorites}
          element={<ProfileFavoritesPage />}
        />
        <Route path={routes.profile.orders} element={<ProfileOrdersPage />} />
        <Route
          path={routes.profile.settings}
          element={<ProfileSettingsPage />}
        />
        <Route
          path={routes.profile.vehicles}
          element={<ProfileVehiclesPage />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </>
    );
  }, [authStateValue]);

  const staticPages = useMemo(() => {
    return (
      <>
        {staticPageArr.map((staticPage) => (
          <Route
            key={staticPage.id}
            path={routes.staticPage.replace(
              ':slug',
              staticPage.seo?.slug ?? staticPage.name,
            )}
            element={<StaticPage name={staticPage.name} />}
          />
        ))}
      </>
    );
  }, [staticPageArr]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutFactory variant={PageLayoutType.public} />}>
          {publicPages}
          {staticPages}
        </Route>
        <Route element={<LayoutFactory variant={PageLayoutType.basket} />}>
          {basketPages}
        </Route>
        <Route element={<LayoutFactory variant={PageLayoutType.profile} />}>
          {profilePages}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteProvider;
