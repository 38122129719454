import { FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  InputFactoryProps,
  InputVariant,
} from '../../common/Input/InputFactory';
import {
  InputValidation,
  validateInput,
} from '../../../utils/validation/validation';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { Asset } from '../../../domain/Asset';
import { useParams } from 'react-router-dom';

export type InputValue = {
  name: string;
  value: InputValueType;
  isDirty: boolean;
  errors: string[];
};

export type InputVisibilityRule = {
  dependedField: string;
  rules: InputValidation[];
};

export type InputValueType = any;

export type InputConfig = {
  name: string;
  defaultValue?: InputValueType;
  type: InputVariant;
  label?: string;
  validation?: InputValidation[];
  inputFactoryProps?: InputFactoryProps;
  visibility?: InputVisibilityRule[];
};

export type SubmitValues = {
  [key: string]: InputValueType;
};

export type SetValues = {
  [key: string]: InputValueType | SetValues | SetValues[];
};

export type FormCallback = {
  onSubmit?: (submitValues: SubmitValues) => void;
  onChange?: (submitValues: SubmitValues) => void;
};

export type UseFormProps = {
  inputValues: InputValue[];
  handleChange: (name: string, value: InputValueType) => void;
  handleBlur: (name: string) => void;
  getSubmitValues: () => SubmitValues | null;
  handleSubmit: (
    event?: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ) => void;
  setValues: (values: SetValues) => void;
  getValue: (name: string) => InputValueType;
  isVisible: (formInput: InputConfig) => boolean;
  resetValues: () => void;
  getInputFactoryProps: (input: InputConfig) => InputFactoryProps;
  getAllValidatedInputs: () => InputValue[];
};

export const useForm = (
  inputs: InputConfig[],
  callbacks?: FormCallback,
): UseFormProps => {
  const translations = useRecoilValue(translationsState);
  const params = useParams();

  const getInitialValue = (input: InputConfig) => {
    if (
      input.inputFactoryProps?.variant === InputVariant.select &&
      input.inputFactoryProps?.isMultiple
    ) {
      return input.defaultValue ?? [];
    }

    if (input.inputFactoryProps?.variant === InputVariant.urlParam) {
      const foundParam = Object.keys(params).find((key) => {
        // @ts-ignore
        return key === input?.inputFactoryProps?.paramName;
      });

      return params[foundParam ?? ''] ?? '';
    }

    if (input.defaultValue !== undefined) {
      return input.defaultValue;
    }

    return '';
  };

  const [inputValues, setInputValues] = useState<InputValue[]>(
    inputs.map((input) => ({
      name: input.name,
      value: getInitialValue(input),
      isDirty: false,
      errors: [],
    })),
  );

  useEffect(() => {
    setInputValues((prevInputValues) => {
      return inputs.map((input) => {
        const existingInput = prevInputValues.find(
          (prevInput) => prevInput.name === input.name,
        );

        return existingInput
          ? { ...existingInput }
          : {
              name: input.name,
              value: getInitialValue(input),
              isDirty: false,
              errors: [],
            };
      });
    });
  }, [JSON.stringify(inputs.map((input) => ({ ...input, label: '' })))]);

  const changeTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetValues = () => {
    setInputValues(
      inputs.map((input) => ({
        name: input.name,
        value: getInitialValue(input),
        isDirty: false,
        errors: [],
      })),
    );
  };

  const validateAllInputs = (): InputValue[] => {
    return inputValues
      .filter((prevInput) => {
        const inputBlueprint = inputs.find(
          (input) => input.name === prevInput.name,
        );

        if (!inputBlueprint) {
          return true;
        }

        return isVisible(inputBlueprint);
      })
      .map((prevInput) => {
        return {
          ...prevInput,
          errors: validateInput(
            prevInput.value ?? '',
            inputValues,
            getInputRules(prevInput.name),
            translations,
          ),
          isDirty: true,
        };
      });
  };

  const handleSubmit = (
    event?: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>,
  ) => {
    event?.preventDefault();

    setInputValues((prev) => {
      const validatedInputs = prev
        .filter((prevInput) => {
          const inputBlueprint = inputs.find(
            (input) => input.name === prevInput.name,
          );

          if (!inputBlueprint) {
            return true;
          }

          return isVisible(inputBlueprint);
        })
        .map((prevInput) => {
          return {
            ...prevInput,
            errors: validateInput(
              prevInput.value ?? '',
              prev,
              getInputRules(prevInput.name),
              translations,
            ),
            isDirty: true,
          };
        });

      if (validatedInputs.some((inputValue) => inputValue.errors.length > 0)) {
        return validatedInputs;
      }

      const submitValues = getSubmitValues();

      if (submitValues) {
        callbacks?.onSubmit?.(submitValues);
      }

      return validatedInputs;
    });
  };

  const handleChange = (name: string, value: InputValueType) => {
    setInputValues((prev) => {
      const newValues = prev?.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              value: value,
            }
          : { ...prevInput },
      );

      if (callbacks?.onChange) {
        handleChangeDebounce(getValues(newValues));
      }

      return newValues;
    });

    validateInputs();
  };

  const handleChangeDebounce = (newValues: SubmitValues | null) => {
    if (changeTimeoutRef.current) {
      clearTimeout(changeTimeoutRef.current);
    }

    changeTimeoutRef.current = setTimeout(() => {
      if (newValues) {
        callbacks?.onChange?.(newValues);
      }
    }, 300);
  };

  const handleBlur = (name: string) => {
    setInputValues((prev) =>
      prev.map((prevInput) =>
        prevInput.name === name
          ? {
              ...prevInput,
              isDirty: true,
            }
          : { ...prevInput },
      ),
    );

    validateInputs();
  };

  const validateInputs = () => {
    setTimeout(() => {
      setInputValues((prev) =>
        prev.map((prevInput) => ({
          ...prevInput,
          errors: prevInput.isDirty
            ? validateInput(
                prevInput.value,
                prev,
                getInputRules(prevInput.name),
                translations,
              )
            : prevInput.errors,
        })),
      );
    });
  };

  const getSubmitValues = (): SubmitValues | null => {
    const submitValues = {};

    inputValues.forEach((item) => {
      _.set(submitValues, item.name, item.value);
    });

    return submitValues;
  };

  const getValues = (newInputValues: InputValue[]): SubmitValues | null => {
    const submitValues = {};

    newInputValues.forEach((item) => {
      _.set(submitValues, item.name, item.value);
    });

    return submitValues;
  };

  const getInputRules = (name: string) => {
    return inputs.find((input) => input.name === name)?.validation ?? [];
  };

  const getInitialSetValue = (key: string, values: SetValues): string => {
    const firstKey = _.first(key.split('.'));

    if (!firstKey) {
      return values[key];
    }

    const value = values[firstKey];

    if (_.isObject(values) && value === undefined) {
      // @ts-ignore
      return values.id;
    }

    if (_.isObject(value) && !Array.isArray(value)) {
      return getInitialSetValue(key.replace(`${firstKey}.`, ''), value);
    }

    return value;
  };

  const setValues = (values: SetValues) => {
    setInputValues((prev) => {
      return prev.map((prevInput) => {
        const inputBlueprint = inputs.find(
          (input) => input.name === prevInput.name,
        );

        if (_.isArray(values[prevInput.name])) {
          const someValue = values[prevInput.name] as SetValues[];

          return {
            ...prevInput,
            value: someValue.map((value) => value.id.toString()),
          };
        }

        if (inputBlueprint?.type === InputVariant.avatar) {
          const valueAsset = values[prevInput.name] as Asset | null;

          return {
            ...prevInput,
            value: valueAsset?.id.toString(),
          };
        }

        if (
          inputBlueprint?.type === InputVariant.select &&
          inputBlueprint?.inputFactoryProps?.variant === InputVariant.select &&
          inputBlueprint?.inputFactoryProps?.isMultiple
        ) {
          const multiValue = getInitialSetValue(prevInput.name, values) as any;

          return {
            ...prevInput,
            value: multiValue.map((value: any) => value.id.toString()),
          };
        }

        return {
          ...prevInput,
          value: getInitialSetValue(prevInput.name, values),
        };
      });
    });
  };

  const getValue = (name: string) => {
    return (
      inputValues.find((inputValue) => inputValue.name === name)?.value ?? ''
    );
  };

  const isVisible = (formInput: InputConfig): boolean => {
    if (!formInput.visibility) {
      return true;
    }

    return !formInput.visibility?.some((visibilityRule) => {
      const validation = validateInput(
        getValue(visibilityRule.dependedField) ?? '',
        inputValues,
        visibilityRule.rules,
        translations,
      );

      return validation.length > 0;
    });
  };

  const getInputFactoryProps = (input: InputConfig): InputFactoryProps => {
    return {
      variant: input.type,
      name: input.name,
      // @ts-ignore
      label: input.label,
      value: getValue(input.name),
      onChange: handleChange,
      onBlur: handleBlur,
      errors:
        inputValues.find((inputValue) => inputValue.name === input.name)
          ?.errors ?? [],
      ...input?.inputFactoryProps,
    };
  };

  return {
    inputValues,
    handleChange,
    handleBlur,
    getSubmitValues,
    handleSubmit,
    setValues,
    getValue,
    getInputFactoryProps,
    isVisible,
    resetValues,
    getAllValidatedInputs: validateAllInputs,
  };
};
