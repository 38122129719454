import {
  BrandProductsRequest,
  CartProductsRequest,
  Product,
  SearchProductsRequest,
  SimilarProductsRequest,
  UserLikedProductsRequest,
} from '../domain/Product';
import { StaticPageType } from '../domain/StaticPage';
import { CategoriesRequest } from '../domain/Category';
import { CurrentUser } from '../domain/User';
import { PublicBlogsByCategoryRequest } from '../domain/Blog';
import { UserOrderItemsRequest } from './orderItems/queries';

export const queryKeys = {
  categories: {
    topNavigation: (params: CategoriesRequest) => [
      'categories',
      'top-navigation',
      JSON.stringify(params),
    ],
    all: (params: CategoriesRequest) => [
      'categories',
      'public',
      'all',
      JSON.stringify(params),
    ],
    homepage: ['homepage'],
  },
  manufacturers: {
    main: ['manufacturers', 'main'],
  },
  brands: {
    options: ['brands', 'options'],
  },
  products: {
    bySlug: (slug?: string) => ['products', slug],
    search: (params: SearchProductsRequest) => [
      'products',
      'search',
      JSON.stringify(params),
    ],
    cartItems: (params: CartProductsRequest) => [
      'products',
      'cart',
      JSON.stringify(params),
    ],
    likedProductsByUser: (
      user: CurrentUser | null,
      params: UserLikedProductsRequest,
      likedProductIds: Product[],
    ) => [user, 'products', 'likedProducts', params, likedProductIds],
    likedProductsByUserIds: (user: CurrentUser | null) => [
      user,
      'products',
      'likedProductsIds',
    ],
    featured: ['products', 'featured'],
    newest: ['products', 'newest'],
    similarProducts: (params: SimilarProductsRequest) => [
      'similar',
      'products',
      params,
    ],
    brandProducts: (params: BrandProductsRequest) => [
      'brand',
      'products',
      params,
    ],
    top: ['products', 'top'],
  },
  vatRates: {
    public: ['vat-rates', 'public'],
  },
  vehicleModels: {
    byBrand: (brandId: number | null) => ['vehicleModels', brandId?.toString()],
  },
  vehicles: {
    byVehicleModel: (vehicleModelId: number | null) => [
      'vehicles',
      vehicleModelId?.toString(),
    ],
    bySelectedVehicle: (vehicleId: number | null) => [
      'vehicles',
      'selected',
      vehicleId?.toString(),
    ],
    userVehicles: ['vehicles', 'user'],
    byIds: (ids: number[]) => ['vehicles', [ids]],
  },
  blogs: {
    byId: (blogId?: number | string) => ['blogs', blogId?.toString()],
    related: (blogId?: number | string) => [
      'blogs',
      blogId?.toString(),
      'related',
    ],
    featured: ['blogs', 'featured'],
    newest: ['blogs', 'newest'],
    byCategorySlug: (params: PublicBlogsByCategoryRequest) => [
      'blogs',
      'category',
      params,
    ],
    recentlyRead: (ids: number[]) => ['blogs', 'recently-read', ids],
  },
  articleCategories: {
    get: ['articleCategories', 'initial'],
    getRemaining: (showAll?: boolean) => ['articleCategories', 'all', showAll],
    bySlug: (slug?: string) => ['articleCategory', slug],
  },
  staticPages: {
    byName: (name: StaticPageType) => ['static-pages', 'name', name],
    all: ['static-pages', 'all'],
  },
  bankDetails: {
    currentUser: ['bank-details', 'me'],
  },
  address: {
    currentUser: ['address', 'me'],
  },
  orderItems: {
    currentUser: (params: UserOrderItemsRequest) => [
      'order-items',
      'me',
      params,
    ],
  },
  faq: {
    all: ['faq', 'all'],
  },
  languages: {
    options: ['languages', 'options'],
  },
};
