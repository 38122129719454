import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../config/api/apiRoutes';
import { queryKeys } from '../keys';
import { CategoriesRequest, Category } from '../../domain/Category';
import axios from '../../libraries/axios/axios';

export const useTopNavigationCategoriesQuery = (params: CategoriesRequest) => {
  const fetchTopCategories = async (
    params: CategoriesRequest,
  ): Promise<Category[]> => {
    const { data } = await axios.get(apiRoutes.categories.topNavigation, {
      params: params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.categories.topNavigation(params),
    queryFn: () => fetchTopCategories(params),
    initialData: [],
  });
};

export const useAllCategoriesQuery = (params: CategoriesRequest) => {
  const fetchAllCategories = async (
    params: CategoriesRequest,
  ): Promise<Category[]> => {
    const { data } = await axios.get(apiRoutes.categories.all, {
      params: params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.categories.all(params),
    queryFn: () => fetchAllCategories(params),
    initialData: [],
  });
};

export const useHomepageCategoriesQuery = () => {
  const fetchHomepageCategories = async (): Promise<Category[]> => {
    const { data } = await axios.get(apiRoutes.categories.homepage);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.categories.homepage,
    queryFn: fetchHomepageCategories,
    initialData: [],
  });
};
