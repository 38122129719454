import { Seo } from './Seo';

export interface StaticPage {
  id: number;
  name: StaticPageType;
  title: string;
  content: string;
  seo?: Seo;
}

export enum StaticPageType {
  ABOUT_US = 'ABOUT_US',
  COMPANY_INFO = 'COMPANY_INFO',
  PAYMENT = 'PAYMENT',
  DELIVERY = 'DELIVERY',
  RETURN_AND_REFUNDS = 'RETURN_AND_REFUNDS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}
