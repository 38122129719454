import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './Subscribe.module.scss';
import Button, { ButtonVariant } from '../../common/Button/Button';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { useNewsletterSubscribeMutation } from '../../../api/newsletter/mutations';
import { toast } from 'sonner';
import {
  FormCallback,
  InputConfig,
  useForm,
} from '../../hooks/useForm/useForm';
import InputFactory, { InputVariant } from '../../common/Input/InputFactory';
import { InputValidationType } from '../../../utils/validation/validation';

export const Subscribe = () => {
  const translations = useRecoilValue(translationsState);

  const {
    mutate: subscribe,
    isSuccess,
    isPending,
  } = useNewsletterSubscribeMutation({
    onSuccess: () => {
      toast.success(translate(translations, 'SUBSCRIBE.SUCCESS'));
      resetValues();
    },
    onError: (error) =>
      toast.error(
        translate(translations, error?.response?.data?.message ?? ''),
      ),
  });

  const inputs: InputConfig[] = [
    {
      name: 'email',
      label: translate(translations, 'SUBSCRIBE.EMAIL_INPUT_LABEL'),
      type: InputVariant.text,
      validation: [
        { type: InputValidationType.required },
        { type: InputValidationType.email },
      ],
    },
    {
      name: 'acceptNewsletter',
      // @ts-ignore
      label: (
        <>
          {translate(translations, 'SUBSCRIBE.CHECKBOX_LABEL')}
          <Link to={routes.homepage}>
            {translate(translations, 'SUBSCRIBE.CHECKBOX_READ_MORE')}
          </Link>
        </>
      ),
      type: InputVariant.checkbox,
      defaultValue: false,
    },
  ];

  const formActions: FormCallback = {
    onSubmit: (values) => subscribe(values),
  };

  const { handleSubmit, getInputFactoryProps, resetValues } = useForm(
    inputs,
    formActions,
  );

  return (
    <Box className={styles.subscribeContainer}>
      <Box className={styles.inner}>
        <Box className={styles.textContainer}>
          <Typography
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: translate(translations, 'SUBSCRIBE.TITLE'),
            }}
          />
          <Typography className={styles.subtitle}>
            {translate(translations, 'SUBSCRIBE.SUBTITLE')}
          </Typography>
        </Box>
        <form className={styles.actionContainer} onSubmit={handleSubmit}>
          <Box className={styles.inputContainer}>
            {inputs
              .filter((inputs) => inputs.name === 'email')
              .map((input) => (
                <InputFactory
                  key={input.name}
                  {...getInputFactoryProps(input)}
                />
              ))}
            <Button
              className={styles.button}
              variant={ButtonVariant.primary}
              onClick={handleSubmit}
              type="submit"
              disabled={isSuccess || isPending}
            >
              {translate(translations, 'SUBSCRIBE.BUTTON')}
            </Button>
          </Box>
          <Box className={styles.agreementContainer}>
            {inputs
              .filter((inputs) => inputs.name === 'acceptNewsletter')
              .map((input) => (
                <InputFactory
                  key={input.name}
                  {...getInputFactoryProps(input)}
                />
              ))}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Subscribe;
