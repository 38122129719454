import React from 'react';
import TextInput, { TextInputProps } from './TextInput/TextInput';
import PasswordInput, {
  PasswordInputProps,
} from './PasswordInput/PasswordInput';
import { SelectInput, SelectInputProps } from './SelectInput/SelectInput';
import AvatarInput, { AvatarInputProps } from './AvatarInput/AvatarInput';
import {
  TextAreaInput,
  TextAreaInputProps,
} from './TextAreaInput/TextAreaInput';
import { CountryInput, CountryInputProps } from './CountryInput/CountryInput';
import HiddenInput, { HiddenInputProps } from './HiddenInput/HiddenInput';
import { PhoneInput, PhoneInputProps } from './PhoneInput/PhoneInput';
import {
  SwitcherInput,
  SwitcherInputProps,
} from './SwitcherInput/SwitcherInput';
import { ActionInput, ActionInputProps } from './ActionInput/ActionInput';
import CheckboxInput, {
  CheckboxInputProps,
} from './CheckboxInput/CheckboxInput';
import CurrencyInput, {
  CurrencyInputProps,
} from './CurrencyInput/CurrencyInput';
import UrlParamInput, {
  UrlParamInputProps,
} from './UrlParamInput/UrlParamInput';
import DateInput, { DateInputProps } from './DateInput/DateInput';
import RichTextInput, {
  RichTextInputProps,
} from './RichTextInput/RickTextInput';
import ButtonSelectInput, {
  ButtonSelectInputProps,
} from './ButtonSelectInput/ButtonSelectInput';
import AttachmentInput, {
  AttachmentInputProps,
} from './AttachmentInput/AttachmentInput';
import DurationInput, {
  DurationInputProps,
} from './DurationInput/DurationInput';

export enum InputVariant {
  'text' = 'text',
  'password' = 'password',
  'select' = 'select',
  'avatar' = 'avatar',
  'textarea' = 'textarea',
  'country' = 'country',
  'hidden' = 'hidden',
  'phone' = 'phone',
  'switcher' = 'switcher',
  'action' = 'action',
  'checkbox' = 'checkbox',
  'currency' = 'currency',
  'urlParam' = 'urlParam',
  'date' = 'date',
  'richText' = 'richText',
  'buttonSelect' = 'buttonSelect',
  'attachment' = 'attachment',
  'duration' = 'duration',
}

export type InputFactoryProps =
  | (TextInputProps & {
      variant: InputVariant.text;
    })
  | (PasswordInputProps & {
      variant: InputVariant.password;
    })
  | (SelectInputProps & {
      variant: InputVariant.select;
    })
  | (AvatarInputProps & {
      variant: InputVariant.avatar;
    })
  | (TextAreaInputProps & {
      variant: InputVariant.textarea;
    })
  | (CountryInputProps & {
      variant: InputVariant.country;
    })
  | (HiddenInputProps & { variant: InputVariant.hidden })
  | (PhoneInputProps & { variant: InputVariant.phone })
  | (SwitcherInputProps & { variant: InputVariant.switcher })
  | (ActionInputProps & { variant: InputVariant.action })
  | (CheckboxInputProps & { variant: InputVariant.checkbox })
  | (CurrencyInputProps & { variant: InputVariant.currency })
  | (UrlParamInputProps & { variant: InputVariant.urlParam })
  | (DateInputProps & { variant: InputVariant.date })
  | (RichTextInputProps & { variant: InputVariant.richText })
  | (ButtonSelectInputProps & { variant: InputVariant.buttonSelect })
  | (AttachmentInputProps & { variant: InputVariant.attachment })
  | (DurationInputProps & { variant: InputVariant.duration });

export const InputFactory = (props: InputFactoryProps) => {
  switch (props.variant) {
    case InputVariant.text:
      return <TextInput {...props} />;
    case InputVariant.password:
      return <PasswordInput {...props} />;
    case InputVariant.select:
      return <SelectInput {...props} />;
    case InputVariant.avatar:
      return <AvatarInput {...props} />;
    case InputVariant.textarea:
      return <TextAreaInput {...props} />;
    case InputVariant.country:
      return <CountryInput {...props} />;
    case InputVariant.hidden:
      return <HiddenInput />;
    case InputVariant.phone:
      return <PhoneInput {...props} />;
    case InputVariant.switcher:
      return <SwitcherInput {...props} />;
    case InputVariant.action:
      return <ActionInput {...props} />;
    case InputVariant.checkbox:
      return <CheckboxInput {...props} />;
    case InputVariant.currency:
      return <CurrencyInput {...props} />;
    case InputVariant.urlParam:
      return <UrlParamInput {...props} />;
    case InputVariant.date:
      return <DateInput {...props} />;
    case InputVariant.richText:
      return <RichTextInput {...props} />;
    case InputVariant.buttonSelect:
      return <ButtonSelectInput {...props} />;
    case InputVariant.attachment:
      return <AttachmentInput {...props} />;
    case InputVariant.duration:
      return <DurationInput {...props} />;
    default:
      return null;
  }
};

export default InputFactory;
