import React from 'react';
import { translate } from '../../../../utils/translation/translation';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import styles from './ConfirmationDialog.module.scss';
import { useMutation } from '@tanstack/react-query';
import type { DefaultError } from '@tanstack/query-core';
import axios from '../../../../libraries/axios/axios';
import { toast } from 'sonner';
import { colors } from '../../../../config/styles/colors';

export type DeleteActionProps = {
  title: string;
  description: string;
  successMessage: string;
  apiUrl: string;
  onSuccess?: () => void;
  isOpen: boolean;
  onClose: () => void;
  bulkDto?: any;
};

export const ConfirmationDialog = ({
  title,
  description,
  successMessage,
  apiUrl,
  onSuccess,
  isOpen,
  onClose,
  bulkDto,
}: DeleteActionProps) => {
  const translations = useRecoilValue(translationsState);

  const { mutate, isPending } = useMutation<unknown, DefaultError>({
    mutationFn: () => {
      if (bulkDto) {
        return axios.post(apiUrl, bulkDto);
      }

      return axios.delete(apiUrl);
    },
    onSuccess: () => {
      toast.success(successMessage);
      onSuccess?.();
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={styles.dialogTitle}>
        {title}
      </DialogTitle>
      <DialogContent
        className={styles.dialogContent}
        style={{ color: colors.textSubtitleColor }}
      >
        {description}
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button onClick={onClose} variant={'outlined'} disabled={isPending}>
          {translate(translations, 'CONFIRMATION_DIALOG.DELETE_CANCEL')}
        </Button>
        <Button
          onClick={() => mutate()}
          variant={'contained'}
          disabled={isPending}
        >
          {translate(translations, 'CONFIRMATION_DIALOG.DELETE_CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
