import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import QuillResizeImage from 'quill-resize-image';
import { InputValueType } from '../../../hooks/useForm/useForm';
import {
  Box,
  Typography,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import styles from './RichTextInput.module.scss';
import cx from 'classnames';
import { colors } from '../../../../config/styles/colors';
import HelperText from '../../HelperText/HelperText';
import { apiRoutes } from '../../../../config/api/apiRoutes';
import axios from '../../../../libraries/axios/axios';
import { Asset } from '../../../../domain/Asset';

Quill.register('modules/resize', QuillResizeImage);

export type RichTextInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: InputValueType) => void;
  onBlur?: Function;
  className?: string;
};

export const RichTextInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  className,
}: RichTextInputProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const [isLoadingImageUpload, setIsLoadingImageUpload] = useState(false); // State to track image upload

  const uploadImage = async (file: File): Promise<string | null> => {
    setIsLoadingImageUpload(true);

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post<Asset>(
        apiRoutes.assets.uploadImage,
        formData,
      );

      if (!response.data) {
        return null;
      }

      return response.data.url;
    } finally {
      setIsLoadingImageUpload(false);
    }
  };

  const handlePaste = async (event: ClipboardEvent) => {
    const clipboardData = event.clipboardData;
    const items = clipboardData?.items;

    if (!items) {
      return;
    }

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();

        if (file) {
          event.preventDefault();
          try {
            const imageUrl = await uploadImage(file);

            if (quillRef.current) {
              const quill = quillRef.current?.getEditor();
              const range = quill.getSelection(true);
              quill.insertEmbed(range.index, 'image', imageUrl);
            }
          } catch (error) {}
        }
      }
    }
  };

  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute(
      'accept',
      '.jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp,.svg,.heif,.heic,.ico,.avif',
    );
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        try {
          const imageUrl = await uploadImage(file);

          if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, 'image', imageUrl);
          }
        } catch (error) {}
      }
    };
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current?.getEditor();

      quill.clipboard.addMatcher('img', (node: any, delta: any) => {
        return delta;
      });

      quill.root.addEventListener('paste', handlePaste);
    }

    return () => {
      if (quillRef.current) {
        const quill = quillRef.current?.getEditor();
        quill.root.removeEventListener('paste', handlePaste);
      }
    };
  }, []);

  const borderColor = useMemo(() => {
    if (errors && errors.length > 0) {
      return colors.inputErrorColor;
    }

    return colors.inputBorderColor;
  }, [errors]);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          ['clean', 'code'],
        ],
        handlers: {
          image: handleImageUpload,
        },
      },
      resize: {
        locale: {},
      },
    };
  }, []);

  return (
    <Box className={cx(styles.richTextInputContainer, className)}>
      {label && (
        <Typography
          variant="subtitle2"
          className={styles.label}
          style={{
            color:
              errors && errors.length > 0 ? colors.inputErrorColor : 'inherit',
          }}
        >
          {label}
        </Typography>
      )}
      <div className={styles.richTextEditorContainer}>
        <ReactQuill
          ref={quillRef}
          className={styles.richTextEditor}
          style={{ borderColor: borderColor }}
          onBlur={() => onBlur?.(name ?? '')}
          value={value}
          onChange={(newValue) => onChange?.(name ?? '', newValue)}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'indent',
            'link',
            'image',
            'code',
            'code-block',
          ]}
          modules={modules}
        />

        {isLoadingImageUpload && (
          <Box
            className={styles.loader}
            style={{
              backgroundColor: colors.loaderBackgroundColor,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </div>

      {errors && errors.length > 0 && (
        <FormHelperText error>
          <HelperText errors={errors} />
        </FormHelperText>
      )}
    </Box>
  );
};

export default RichTextInput;
