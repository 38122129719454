import React, { useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './TopNavigation.module.scss';
import TopNavigationIconItem from './TopNavigationIconItem/TopNavigationIconItem';
import { useRecoilState, useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import SearchInput from '../../../components/SearchInput/SearchInput';
import CartInfo from './CartInfo/CartInfo';
import { useWindowWidth } from '@react-hook/window-size';
import MobileDrawer from './MobileDrawer/MobileDrawer';
import LoginModal from '../../../components/LoginModal/LoginModal';
import MyGarageMenu from '../../../components/MyGarageMenu/MyGarageMenu';
import logo from '../../../../assets/logo.svg';
import garageIcon from '../../../../assets/icons/garage.svg';
import userIcon from '../../../../assets/icons/user.svg';
import { currentUserState } from '../../../../atoms/user/user.atom';
import { useTopNavigationCategoriesQuery } from '../../../../api/categories/queries';
import { loginModalState } from '../../../../atoms/auth/auth.atom';

const MOBILE_BREAKPOINT = 1200;

export const TopNavigation = () => {
  const translations = useRecoilValue(translationsState);
  const currentUser = useRecoilValue(currentUserState);
  const [isLoginModalOpen, setIsLoginModalOpen] =
    useRecoilState(loginModalState);

  const navigate = useNavigate();
  const width = useWindowWidth();
  const [isGarageMenu, setIsGarageMenu] = useState(false);
  const garageTitleContainerRef = useRef<HTMLDivElement>(null);
  const { data: topNavigationCategories } = useTopNavigationCategoriesQuery({});

  const isMobile = useMemo(() => width < MOBILE_BREAKPOINT, [width]);

  return (
    <>
      <Box className={styles.topNavigationContainer}>
        <Box className={styles.innerContainer}>
          <Box className={styles.upperPart}>
            {isMobile && <MobileDrawer />}
            <Link to={routes.homepage}>
              <img src={logo} />
            </Link>
            {!isMobile && (
              <Box className={styles.upperIconItems}>
                <Box ref={garageTitleContainerRef}>
                  <TopNavigationIconItem
                    icon={garageIcon}
                    title={translate(
                      translations,
                      'TOP_NAVIGATION.MY_GARAGE_TITLE',
                    )}
                    subtitle={translate(
                      translations,
                      'TOP_NAVIGATION.MY_GARAGE_SUBTITLE',
                    )}
                    onClick={() => setIsGarageMenu(true)}
                  />
                  <MyGarageMenu
                    isOpen={isGarageMenu}
                    onClose={() => setIsGarageMenu(false)}
                    anchorEl={garageTitleContainerRef.current}
                  />
                </Box>
                <TopNavigationIconItem
                  icon={userIcon}
                  title={translate(
                    translations,
                    'TOP_NAVIGATION.MY_PARTS_TITLE',
                  )}
                  subtitle={
                    currentUser?.profile.firstName ??
                    translate(translations, 'TOP_NAVIGATION.MY_PARTS_SUBTITLE')
                  }
                  onClick={() =>
                    currentUser?.identity
                      ? navigate(routes.profile.vehicles)
                      : setIsLoginModalOpen(true)
                  }
                />
              </Box>
            )}
            {isMobile && <CartInfo />}
          </Box>
          <Box className={styles.searchItemsContainer}>
            <SearchInput />
            {!isMobile && <CartInfo />}
          </Box>
          {!isMobile && (
            <Box className={styles.partTypesContainer}>
              {topNavigationCategories.map((category) => (
                <Link
                  to={`${routes.products}?category=${category.id}`}
                  key={category.id}
                  className={styles.type}
                >
                  <img className={styles.typeIcon} src={category.image?.url} />
                  <Typography className={styles.typeTitle}>
                    {category.name}
                  </Typography>
                </Link>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default TopNavigation;
