import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../config/api/apiRoutes';
import {
  BrandProductsRequest,
  CartProductsRequest,
  Product,
  SearchProductsRequest,
  SearchProductsResponse,
  SimilarProductsRequest,
  UserLikedProductsRequest,
  UserLikedProductsResponse,
} from '../../domain/Product';
import axios from '../../libraries/axios/axios';
import { queryKeys } from '../keys';
import { CurrentUser } from '../../domain/User';

export const useProductQuery = (slug?: string) => {
  const fetchProduct = async (slug?: string): Promise<Product> => {
    const { data } = await axios.get(
      apiRoutes.products.getProduct.replace(':slug', slug ?? ''),
    );
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.bySlug(slug),
    queryFn: () => fetchProduct(slug!),
    enabled: !!slug,
  });
};

export const useSearchProductsQuery = (params: SearchProductsRequest) => {
  const searchProducts = async (
    params: SearchProductsRequest,
  ): Promise<SearchProductsResponse> => {
    const { data } = await axios.get(apiRoutes.products.searchProducts, {
      params: params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.search(params),
    queryFn: () => searchProducts(params),
  });
};

export const useCartProductsQuery = (params: CartProductsRequest) => {
  const getCartProducts = async (
    params: CartProductsRequest,
  ): Promise<Product[]> => {
    const { data } = await axios.get(apiRoutes.products.cartItems, {
      params: params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.cartItems(params),
    queryFn: () => getCartProducts(params),
  });
};

export const useFeaturedProductsQuery = () => {
  const getFeaturedProducts = async (): Promise<Product[]> => {
    const { data } = await axios.get(apiRoutes.products.featuredProducts);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.featured,
    queryFn: () => getFeaturedProducts(),
  });
};

export const useTopProductsQuery = () => {
  const fetchTopProducts = async (): Promise<Product[]> => {
    const { data } = await axios.get(apiRoutes.products.topProducts);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.top,
    queryFn: () => fetchTopProducts(),
  });
};

export const useLikedProductsQuery = (
  params: UserLikedProductsRequest,
  currentUser: CurrentUser | null,
  likedProductIds: Product[],
) => {
  const fetchPaginatedUserLikedProducts =
    async (): Promise<UserLikedProductsResponse> => {
      const { data } = await axios.get(
        apiRoutes.products.getPaginatedUserLikedProducts,
        {
          params: params,
        },
      );

      return data;
    };

  return useQuery({
    queryKey: queryKeys.products.likedProductsByUser(
      currentUser,
      params,
      likedProductIds,
    ),
    queryFn: () => fetchPaginatedUserLikedProducts(),
    enabled: !!currentUser,
    initialData: {
      result: [],
      total: 0,
    },
  });
};

export const useLikedProductsIdsQuery = (currentUser: CurrentUser | null) => {
  const fetchUserLikedProductIds = async (): Promise<Product[]> => {
    const { data } = await axios.get(
      apiRoutes.products.getUserLikedProductsIds,
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.likedProductsByUserIds(currentUser),
    queryFn: () => fetchUserLikedProductIds(),
    enabled: !!currentUser,
    initialData: [],
  });
};

export const useSimilarProductsQuery = (params: SimilarProductsRequest) => {
  const getSimilarProducts = async (
    params: SimilarProductsRequest,
  ): Promise<Product[]> => {
    const { data } = await axios.get(apiRoutes.products.similarProducts, {
      params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.similarProducts(params),
    queryFn: () => getSimilarProducts(params),
    initialData: [],
    enabled: !!params.productIds?.length,
  });
};

export const useBrandProductsQuery = (params: BrandProductsRequest) => {
  const getBrandProducts = async (
    params: BrandProductsRequest,
  ): Promise<Product[]> => {
    const { data } = await axios.get(apiRoutes.products.brandProducts, {
      params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.brandProducts(params),
    queryFn: () => getBrandProducts(params),
    initialData: [],
    enabled: !!params.brandId,
  });
};

export const useNewestProductsQuery = () => {
  const getNewestProducts = async (): Promise<Product[]> => {
    const { data } = await axios.get(apiRoutes.products.newestProducts);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.products.newest,
    queryFn: () => getNewestProducts(),
  });
};
