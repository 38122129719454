import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../config/api/apiRoutes';
import {
  Blog,
  PublicBlogsByCategoryRequest,
  PublicBlogsResponse,
} from '../../domain/Blog';
import axios from '../../libraries/axios/axios';
import { queryKeys } from '../keys';

export const useBlogQuery = (id?: number | string) => {
  const fetchBlog = async (id: number | string): Promise<Blog> => {
    const { data } = await axios.get(
      apiRoutes.blogs.get.replace(':id', id.toString()),
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.blogs.byId(id),
    queryFn: () => fetchBlog(id!),
    enabled: !!id,
  });
};

export const useRelatedBlogsQuery = (id?: number | string) => {
  const fetchRelatedBlogs = async (id: number | string): Promise<Blog[]> => {
    const { data } = await axios.get(
      apiRoutes.blogs.getRelatedBlogs.replace(':id', id.toString()),
    );

    return data;
  };

  return useQuery({
    queryKey: queryKeys.blogs.related(id),
    queryFn: () => fetchRelatedBlogs(id!),
    enabled: !!id,
    initialData: [],
  });
};

export const useFeaturedBlogsQuery = () => {
  const fetchFeaturedBlogs = async (): Promise<Blog[]> => {
    const { data } = await axios.get(apiRoutes.blogs.getFeaturedBlogs);

    return data;
  };

  return useQuery({
    queryKey: queryKeys.blogs.featured,
    queryFn: fetchFeaturedBlogs,
    initialData: [],
  });
};

export const useRecentlyReadBlogsQuery = (ids: number[]) => {
  const fetchRecentlyReadBlogs = async (ids: number[]): Promise<Blog[]> => {
    const { data } = await axios.get(apiRoutes.blogs.getRecentlyReadBlogs, {
      params: { ids },
    });

    return data;
  };

  return useQuery({
    queryKey: queryKeys.blogs.recentlyRead(ids),
    queryFn: () => fetchRecentlyReadBlogs(ids),
    initialData: [],
    enabled: ids.length > 0,
  });
};

export const useNewestBlogsQuery = () => {
  const fetchNewestBlogs = async (): Promise<Blog[]> => {
    const { data } = await axios.get(apiRoutes.blogs.getNewestBlogs);

    return data;
  };

  return useQuery({
    queryKey: queryKeys.blogs.newest,
    queryFn: () => fetchNewestBlogs(),
    initialData: [],
  });
};

export const usePublicBlogsByCategoryQuery = (
  params: PublicBlogsByCategoryRequest,
) => {
  const fetchPublicBlogsByCategoryCategory = async (
    params: PublicBlogsByCategoryRequest,
  ): Promise<PublicBlogsResponse> => {
    const { data } = await axios.get(apiRoutes.blogs.getBlogsByCategory, {
      params,
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.blogs.byCategorySlug(params),
    queryFn: () => fetchPublicBlogsByCategoryCategory(params),
    enabled: !!params.slug,
  });
};
