import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export const validateEmail = (
  value: InputValueType,
  translations: Translation[],
): string => {
  if (Array.isArray(value)) {
    return (
      value.find((singleValue) => !!validateEmail(singleValue, translations)) ??
      ''
    );
  }

  if (
    !value.toLowerCase().match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  ) {
    return translate(translations, 'VALIDATION.EMAIL');
  }

  return '';
};
