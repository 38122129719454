import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../libraries/axios/axios';
import { Basket } from '../../domain/Basket';
import { HttpError } from '../../types/Api';
import { SubmitValues } from '../../app/hooks/useForm/useForm';
import { apiRoutes } from '../../config/api/apiRoutes';

type UpdateUserBasketRequest = { token: string } & SubmitValues;

export const useCreateOrUpdateUserBasketMutation = (
  options?: UseMutationOptions<Basket, HttpError, UpdateUserBasketRequest>,
) => {
  const postUserBasket = async ({
    token,
    ...values
  }: UpdateUserBasketRequest): Promise<Basket> => {
    const { data } = await axios.post(
      apiRoutes.basket.createOrUpdateUserBasket.replace(':token', token),
      values,
    );
    return data;
  };

  return useMutation<Basket, HttpError, UpdateUserBasketRequest>({
    mutationFn: postUserBasket,
    ...options,
    onSuccess: (...params) => {
      options?.onSuccess?.(...params);
    },
  });
};
