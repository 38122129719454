import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './TotalAmount.module.scss';
import downIcon from '../../../../../assets/icons/down.svg';
import { NumericFormat } from 'react-number-format';
import { ReactSVG } from 'react-svg';

export type TotalAmountProps = {
  amount: number;
};

export const TotalAmount = ({ amount }: TotalAmountProps) => {
  return (
    <Box className={styles.totalAmountContainer}>
      <NumericFormat
        value={amount}
        decimalScale={2}
        fixedDecimalScale
        thousandsGroupStyle="lakh"
        thousandSeparator="."
        decimalSeparator=","
        displayType="text"
        renderText={(value) => (
          <Typography className={styles.amount}>{value} €</Typography>
        )}
      />
      <ReactSVG src={downIcon} className={styles.downIcon} />
    </Box>
  );
};

export default TotalAmount;
