import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { HttpError } from '../../types/Api';
import { SubmitValues } from '../../app/hooks/useForm/useForm';
import axios from '../../libraries/axios/axios';
import { apiRoutes } from '../../config/api/apiRoutes';

export const useNewsletterSubscribeMutation = (
  options: UseMutationOptions<void, HttpError, SubmitValues>,
) => {
  const postNewsletterSubscribe = async (data: SubmitValues): Promise<void> => {
    return await axios.post(apiRoutes.newsletter.subscribe, data);
  };

  return useMutation<void, HttpError, SubmitValues>({
    mutationFn: postNewsletterSubscribe,
    ...options,
  });
};
