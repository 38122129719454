import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export type MatchingValueValidator = {
  value: string;
  isNot?: boolean;
};

export const validateMatchingValue = (
  value: InputValueType,
  rule: MatchingValueValidator,
  translations: Translation[],
) => {
  if (rule.isNot) {
    if (value === rule.value) {
      return translate(translations, 'VALIDATION.VALUES_DO_NOT_MATCH');
    }

    return '';
  }

  if (value !== rule.value) {
    return translate(translations, 'VALIDATION.VALUES_DO_NOT_MATCH');
  }

  return '';
};
