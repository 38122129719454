import React from 'react';
import styles from './MyGarageMenu.module.scss';
import { Menu } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import Modal from '../../common/Modal/Modal';
import MyGarage from './MyGarage/MyGarage';

type Props = {
  anchorEl?: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
};

const MOBILE_BREAKPOINT = 900;

const MyGarageMenu = ({ isOpen, onClose, anchorEl }: Props) => {
  const width = useWindowWidth();

  if (width < MOBILE_BREAKPOINT) {
    return (
      <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
        <MyGarage onSelect={onClose} />
      </Modal>
    );
  }

  return (
    <Menu
      anchorEl={anchorEl ?? undefined}
      open={isOpen}
      onClose={onClose}
      className={styles.menu}
    >
      <MyGarage onSelect={onClose} />
    </Menu>
  );
};

export default MyGarageMenu;
