import React, { useMemo } from 'react';
import { TextField } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import InputMask from 'react-input-mask';
import HelperText from '../../HelperText/HelperText';

export type DurationInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: InputValueType) => void;
  onBlur?: Function;
  className?: string;
};

export const DurationInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  className,
}: DurationInputProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hoursStr, minutesStr] = e.target.value.split(':');
    const hours = parseInt(hoursStr, 10) || 0;
    const minutes = parseInt(minutesStr, 10) || 0;

    onChange?.(name ?? '', hours * 60 + minutes);
  };

  const displayedValue = useMemo(() => {
    const displayHours = Math.floor(value! / 60);
    const displayMinutes = value! % 60;
    return `${String(displayHours).padStart(2, '0')}:${String(
      displayMinutes,
    ).padStart(2, '0')}`;
  }, [value]);

  return (
    <InputMask
      mask="99:99"
      value={displayedValue ?? ''}
      onChange={handleChange}
      onBlur={() => onBlur?.(name)}
    >
      {/* @ts-ignore*/}
      {(inputProps) => (
        <TextField
          {...inputProps}
          label={label}
          variant="outlined"
          fullWidth
          helperText={
            errors && errors.length > 0 && <HelperText errors={errors} />
          }
          error={errors && errors.length > 0}
          className={className}
        />
      )}
    </InputMask>
  );
};

export default DurationInput;
