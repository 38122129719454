import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Menu, Typography } from '@mui/material';
import styles from './CartInfo.module.scss';
import { translate } from '../../../../../utils/translation/translation';
import TopNavigationIconItem from '../TopNavigationIconItem/TopNavigationIconItem';
import cartIcon from '../../../../../assets/icons/cart.svg';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../../atoms/translation/translation.atom';
import TotalAmount from '../TotalAmount/TotalAmount';
import { useWindowWidth } from '@react-hook/window-size';
import Button, { ButtonVariant } from '../../../../common/Button/Button';
import { NumericFormat } from 'react-number-format';
import { routes } from '../../../../../config/routes/routes';
import { useNavigate } from 'react-router-dom';
import { useCartProductsQuery } from '../../../../../api/products/queries';
import { currentCartItemsState } from '../../../../../atoms/cart/cart.atom';
import placeholderImage from '../../../../../assets/placeholder.png';
import * as _ from 'lodash';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../../../utils/localStorage/localStorage';

const MOBILE_BREAKPOINT = 900;

export const CartInfo = () => {
  const translations = useRecoilValue(translationsState);
  const cartItems = useRecoilValue(currentCartItemsState);

  const navigate = useNavigate();
  const width = useWindowWidth();
  const containerRef = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const { data: products } = useCartProductsQuery({
    products: cartItems.map((cartItem) => cartItem.productId),
  });

  useEffect(() => {
    setLocalStorageValue(LocalStorageKey.cart, cartItems);
  }, [cartItems]);

  const getQuantity = (productId: number) => {
    return (
      cartItems.find((cartItem) => cartItem.productId === productId)
        ?.quantity ?? 0
    );
  };

  const totalPrice = useMemo(() => {
    if (!products) {
      return 0;
    }

    return _.sum(
      cartItems.map(
        (cartItem) =>
          cartItem.quantity *
          (products.find((product) => product.id === cartItem.productId)
            ?.price ?? 0),
      ),
    );
  }, [cartItems, products]);

  return (
    <>
      <Box
        ref={containerRef}
        className={styles.cartInfoContainer}
        onClick={() => setMenuOpen(true)}
      >
        <TopNavigationIconItem
          icon={cartIcon}
          title={translate(translations, 'CART_INFO.ITEMS')}
          showTitle={width >= MOBILE_BREAKPOINT}
          subtitle="#74-3088"
          showSubtitle={false}
          total={products?.length ?? 0}
        />
        {width >= MOBILE_BREAKPOINT && <TotalAmount amount={totalPrice} />}
      </Box>
      <Menu
        anchorEl={containerRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        className={styles.menu}
      >
        <Box className={styles.cartItemContainer}>
          {products?.map((product) => (
            <Box
              onClick={() =>
                navigate(
                  routes.product.replace(':slug', product.seo?.slug ?? ''),
                )
              }
              key={product.id}
              className={styles.cartItem}
            >
              <img src={product.mainImage?.url ?? placeholderImage} />

              <Box className={styles.cartItemInfo}>
                <Typography className={styles.cartItemName}>
                  {product.name}
                </Typography>
                <Box className={styles.cartItemPriceContainer}>
                  <NumericFormat
                    value={product.price}
                    decimalScale={2}
                    thousandsGroupStyle="lakh"
                    thousandSeparator=" "
                    decimalSeparator=","
                    displayType="text"
                    renderText={(value) => (
                      <Typography className={styles.cartItemPrice}>
                        {value} €
                      </Typography>
                    )}
                  />
                  <Typography className={styles.cartItemQuantity}>
                    ×{getQuantity(product.id)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box className={styles.summaryContainer}>
          <Typography className={styles.summaryText}>
            {translate(translations, 'CART_INFO.TOTAL_COST')}
          </Typography>
          <NumericFormat
            value={totalPrice}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="lakh"
            thousandSeparator=" "
            decimalSeparator=","
            displayType="text"
            renderText={(value) => (
              <Typography className={styles.summaryPrice}>{value} €</Typography>
            )}
          />
        </Box>
        <Box className={styles.btnContainer}>
          <Button
            onClick={() => navigate(routes.checkoutOrder)}
            className={styles.orderBtn}
          >
            {translate(translations, 'CART_INFO.ORDER_BUTTON')}
          </Button>
          <Button
            onClick={() => navigate(routes.checkout)}
            variant={ButtonVariant.outline}
            className={styles.cartBtn}
          >
            {translate(translations, 'CART_INFO.CART_BUTTON')}
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default CartInfo;
