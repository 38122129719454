import React from 'react';
import styles from './ArticleCard.module.scss';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Button, { ButtonVariant } from '../../common/Button/Button';
import { ReactSVG } from 'react-svg';
import cx from 'classnames';
import arrowRightIcon from '../../../assets/icons/right.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { Blog } from '../../../domain/Blog';

type Props = {
  vertical?: boolean;
  blog: Blog;
};

const ArticleCard = ({ vertical = false, blog }: Props) => {
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();

  return (
    <Box className={cx(styles.articleCard, { [styles.vertical]: vertical })}>
      <img alt={blog.title} src={blog.image.url} className={styles.thumbnail} />

      <Box className={styles.content}>
        <Typography className={styles.title}>{blog.title}</Typography>
        <Typography className={styles.excerpt}>{blog.excerpt}</Typography>

        <Button
          variant={ButtonVariant.contained}
          className={styles.button}
          onClick={() =>
            navigate(routes.articles.article.replace(':id', blog.id.toString()))
          }
        >
          {translate(translations, 'ARTICLE_CARD.READ_MORE_BUTTON')}
          <ReactSVG src={arrowRightIcon} />
        </Button>
      </Box>
    </Box>
  );
};

export default ArticleCard;
