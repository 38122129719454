import React, { SyntheticEvent, useEffect, useMemo } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import type { DefaultError } from '@tanstack/query-core';
import axios from '../../../../libraries/axios/axios';
import { InputValueType } from '../../../hooks/useForm/useForm';
import _ from 'lodash';
import HelperText from '../../HelperText/HelperText';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { useSearchParams } from 'react-router-dom';

export type SelectOption = {
  value: string;
  label: string;
};

export type SelectInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: InputValueType) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
  options?: SelectOption[];
  optionsUrl?: string;
  isMultiple?: boolean;
  defaultValueFromQueryParamKey?: string;
};

export const SelectInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
  options = [],
  optionsUrl,
  isMultiple,
  defaultValueFromQueryParamKey,
}: SelectInputProps) => {
  const translations = useRecoilValue(translationsState);
  const [searchParams] = useSearchParams();

  const { mutate, data: response } = useMutation<
    { data: SelectOption[] },
    DefaultError
  >({
    mutationFn: () => {
      return axios.get(optionsUrl ?? '');
    },
  });

  useEffect(() => {
    if (optionsUrl) {
      mutate();
    }
  }, []);

  useEffect(() => {
    if (!defaultValueFromQueryParamKey) {
      return;
    }

    if (searchParams.get(defaultValueFromQueryParamKey)) {
      onChange?.(name ?? '', searchParams.get(defaultValueFromQueryParamKey));
    }
  }, [defaultValueFromQueryParamKey]);

  const translatedOptions = useMemo(() => {
    return (
      response?.data.map((option) => ({
        ...option,
        label: translate(translations, option.label),
      })) ?? []
    );
  }, [response?.data]);

  const valueOption = useMemo(() => {
    if (isMultiple) {
      return (
        translatedOptions.filter((selectOption) =>
          value?.includes(selectOption.value.toString()),
        ) ?? []
      );
    }

    return (
      translatedOptions.find(
        (selectOption) => selectOption.value.toString() === value?.toString(),
      ) ?? null
    );
  }, [translatedOptions, value, isMultiple]);

  const handleOnChange = (
    event: SyntheticEvent,
    changedValue:
      | SelectOption
      | SelectOption[]
      | (SelectOption | SelectOption[])[]
      | null,
  ) => {
    if (_.isArray(changedValue)) {
      onChange?.(
        name ?? '',
        changedValue.map((selectOption) => {
          return _.isArray(selectOption)
            ? selectOption.toString()
            : selectOption.value.toString();
        }),
      );
      return;
    }

    onChange?.(name ?? '', changedValue?.value.toString() ?? '');
  };

  return (
    <Autocomplete
      id="select-input"
      options={translatedOptions?.length ? translatedOptions : options}
      value={valueOption}
      className={className}
      autoFocus={autoFocus}
      onBlur={() => onBlur?.(name ?? '')}
      multiple={isMultiple}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          helperText={
            errors && errors.length > 0 && <HelperText errors={errors} />
          }
          error={errors && errors.length > 0}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            key={index}
            label={option.label}
            size="small"
          />
        ))
      }
      onChange={handleOnChange}
    />
  );
};

export default SelectInput;
