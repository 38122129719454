import React, { useEffect, useState } from 'react';
import styles from './LoginModal.module.scss';
import { Alert, Box, Typography } from '@mui/material';
import Modal from '../../common/Modal/Modal';
import Button from '../../common/Button/Button';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import InputFactory, { InputVariant } from '../../common/Input/InputFactory';
import {
  FormCallback,
  InputConfig,
  useForm,
} from '../../hooks/useForm/useForm';
import {
  useEmailRegisterMutation,
  useEmailLoginMutation,
  usePasswordResetRequestMutation,
  usePasswordResetMutation,
} from '../../../api/auth/mutations';
import { InputValidationType } from '../../../utils/validation/validation';

export enum AuthState {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  RESET_REQUEST = 'RESET_REQUEST',
  RESET = 'RESET',
}

type ConditionalInputConfig = InputConfig & { showIn: AuthState[] };

type Props = {
  isOpen: boolean;
  onClose: () => void;
  state?: AuthState;
  resetToken?: string | null;
};

const LoginModal = ({
  isOpen,
  onClose,
  state = AuthState.LOGIN,
  resetToken,
}: Props) => {
  const translations = useRecoilValue(translationsState);
  const [authState, setAuthState] = useState(state);

  const {
    mutate: register,
    error: registerError,
    isSuccess: isRegisterSuccess,
    reset: resetRegister,
  } = useEmailRegisterMutation();
  const {
    mutate: login,
    error: loginError,
    isSuccess: isLoginSuccess,
    reset: resetLogin,
  } = useEmailLoginMutation();
  const {
    mutate: requestPasswordReset,
    error: requestPasswordResetError,
    isSuccess: isRequestPasswordResetSuccess,
    reset: resetRequestPasswordReset,
  } = usePasswordResetRequestMutation();
  const {
    mutate: resetPassword,
    error: resetPasswordError,
    isSuccess: isResetPasswordSuccess,
    reset: resetPasswordReset,
  } = usePasswordResetMutation();

  const isSuccess =
    isRegisterSuccess ||
    isLoginSuccess ||
    isRequestPasswordResetSuccess ||
    isResetPasswordSuccess;

  const error =
    registerError ||
    loginError ||
    requestPasswordResetError ||
    resetPasswordError;

  const inputs: ConditionalInputConfig[] = [
    {
      name: 'firstName',
      label: translate(translations, 'LOGIN_MODAL.NAME_INPUT_LABEL'),
      type: InputVariant.text,
      validation: [
        { type: InputValidationType.required },
        { type: InputValidationType.minStringLength, length: 2 },
        { type: InputValidationType.maxStringLength, length: 50 },
      ],
      showIn: [AuthState.REGISTER],
    },
    {
      name: 'lastName',
      label: translate(translations, 'LOGIN_MODAL.SURNAME_INPUT_LABEL'),
      type: InputVariant.text,
      validation: [
        { type: InputValidationType.required },
        { type: InputValidationType.minStringLength, length: 2 },
        { type: InputValidationType.maxStringLength, length: 50 },
      ],
      showIn: [AuthState.REGISTER],
    },
    {
      name: 'email',
      label: translate(translations, 'LOGIN_MODAL.EMAIL_INPUT_LABEL'),
      type: InputVariant.text,
      validation: [
        { type: InputValidationType.required },
        { type: InputValidationType.email },
      ],
      defaultValue: '',
      showIn: [AuthState.LOGIN, AuthState.REGISTER, AuthState.RESET_REQUEST],
    },
    {
      name: 'password',
      label: translate(translations, 'LOGIN_MODAL.PASSWORD_INPUT_LABEL'),
      type: InputVariant.password,
      validation: [
        { type: InputValidationType.required },
        { type: InputValidationType.minStringLength, length: 8 },
        { type: InputValidationType.maxStringLength, length: 30 },
      ],
      showIn: [AuthState.LOGIN, AuthState.REGISTER, AuthState.RESET],
    },
    {
      name: 'passwordConfirm',
      label: translate(
        translations,
        'LOGIN_MODAL.PASSWORD_CONFIRM_INPUT_LABEL',
      ),
      type: InputVariant.password,
      validation: [
        { type: InputValidationType.matchingPasswords, name: 'password' },
      ],
      showIn: [AuthState.RESET],
    },
    {
      name: 'agreedMarketing',
      label: translate(translations, 'LOGIN_MODAL.AGREE_MARKETING_LABEL'),
      type: InputVariant.checkbox,
      defaultValue: false,
      showIn: [AuthState.REGISTER],
    },
  ];

  const filteredInputs = inputs.filter((input) =>
    input.showIn.includes(authState),
  );

  const formActions: FormCallback = {
    onSubmit: (values) => {
      authState === AuthState.LOGIN && login(values);
      authState === AuthState.REGISTER && register(values);
      authState === AuthState.RESET_REQUEST && requestPasswordReset(values);
      authState === AuthState.RESET &&
        resetPassword({ ...values, token: resetToken });
    },
  };

  const { handleSubmit, getInputFactoryProps, resetValues } = useForm(
    filteredInputs,
    formActions,
  );

  useEffect(() => {
    resetValues();

    if (authState !== AuthState.RESET) {
      setAuthState(AuthState.LOGIN);
    }
  }, [isOpen]);

  useEffect(() => {
    resetLogin();
    resetRegister();
    resetRequestPasswordReset();
    resetPasswordReset();
  }, [authState]);

  useEffect(() => {
    if (isLoginSuccess) {
      onClose();
    }
  }, [isLoginSuccess]);

  useEffect(() => {
    if (isResetPasswordSuccess) {
      setAuthState(AuthState.LOGIN);
    }
  }, [isResetPasswordSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <form className={styles.loginForm} onSubmit={handleSubmit}>
        <Typography className={styles.title}>
          {translate(translations, `LOGIN_MODAL.${authState}_TITLE`)}
        </Typography>

        <Box className={styles.inputContainer}>
          {filteredInputs
            .filter((input) =>
              [
                'firstName',
                'lastName',
                'email',
                'password',
                'passwordConfirm',
              ].includes(input.name),
            )
            .map((input) => (
              <InputFactory key={input.name} {...getInputFactoryProps(input)} />
            ))}
        </Box>
        <Box
          className={cx(styles.checkBoxContainer, {
            [styles.register]: authState === AuthState.REGISTER,
          })}
        >
          {authState === AuthState.REGISTER && (
            <>
              <Typography
                className={styles.actionText}
                dangerouslySetInnerHTML={{
                  __html: translate(translations, 'LOGIN_MODAL.PRIVACY_POLICY'),
                }}
              ></Typography>
              {filteredInputs
                .filter((input) => ['agreedMarketing'].includes(input.name))
                .map((input) => (
                  <InputFactory
                    key={input.name}
                    {...getInputFactoryProps(input)}
                    // @ts-ignore
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: input.label ?? '' }}
                      ></span>
                    }
                  />
                ))}
            </>
          )}
          {authState === AuthState.LOGIN && (
            <Typography
              className={cx(styles.actionText, styles.highlighted)}
              onClick={() => setAuthState(AuthState.RESET_REQUEST)}
            >
              {translate(translations, 'LOGIN_MODAL.FORGOT_PASSWORD')}
            </Typography>
          )}
        </Box>

        {!!error && (
          <Alert className={styles.alert} color="error">
            {translate(translations, error?.response?.data?.message ?? '')}
          </Alert>
        )}

        {isSuccess && (
          <Alert className={styles.alert} color="success">
            {translate(translations, `LOGIN_FORM.${authState}_SUCCESS`)}
          </Alert>
        )}

        <Button
          wrapperClassName={styles.loginBtn}
          onClick={handleSubmit}
          disabled={isSuccess}
          type="submit"
        >
          {translate(translations, `LOGIN_MODAL.${authState}_BUTTON`)}
        </Button>

        {authState === AuthState.LOGIN && (
          <Typography className={styles.description}>
            {translate(translations, 'LOGIN_MODAL.REGISTER_DESCRIPTION_PART_1')}
            <span
              className={styles.highlighted}
              onClick={() => setAuthState(AuthState.REGISTER)}
            >
              {translate(
                translations,
                'LOGIN_MODAL.REGISTER_DESCRIPTION_PART_2',
              )}
            </span>
            {translate(translations, 'LOGIN_MODAL.REGISTER_DESCRIPTION_PART_3')}
          </Typography>
        )}
      </form>
    </Modal>
  );
};

export default LoginModal;
