import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { SubmitValues } from '../../app/hooks/useForm/useForm';
import { apiRoutes } from '../../config/api/apiRoutes';
import { HttpError } from '../../types/Api';
import axios, { SuccessResponse } from '../../libraries/axios/axios';
import { SelectedVehicle } from '../../domain/Vehicle';
import { queryKeys } from '../keys';

export const useSendFindVehicleHelpEmailMutation = (
  options: UseMutationOptions<void, HttpError, SubmitValues>,
) => {
  const postSendFindVehicleEmail = async (
    data: SubmitValues,
  ): Promise<void> => {
    return await axios.post(apiRoutes.vehicles.sendFindVehicleHelpEmail, data);
  };

  return useMutation<void, HttpError, SubmitValues>({
    ...options,
    mutationFn: postSendFindVehicleEmail,
  });
};

export const useAddUserVehicleMutation = (
  options?: UseMutationOptions<
    SuccessResponse<SelectedVehicle>,
    HttpError,
    number | string
  >,
) => {
  const queryClient = useQueryClient();

  const patchUserVehicles = async (
    vehicleId: string | number,
  ): Promise<SuccessResponse<SelectedVehicle>> => {
    return await axios.patch(
      apiRoutes.vehicles.addUserVehicle.replace(':id', vehicleId.toString()),
    );
  };

  const updateQuery = (response: SuccessResponse<SelectedVehicle>) => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.vehicles.userVehicles,
    });
    queryClient.setQueryData(
      queryKeys.vehicles.bySelectedVehicle(response.data.id),
      response.data,
    );
  };

  return useMutation<
    SuccessResponse<SelectedVehicle>,
    HttpError,
    number | string
  >({
    mutationFn: patchUserVehicles,
    ...options,
    onSuccess: (response, ...params) => {
      updateQuery(response);
      options?.onSuccess?.(response, ...params);
    },
  });
};

export const useDeleteUserVehicleMutation = (
  options?: UseMutationOptions<void, HttpError, number | string>,
) => {
  const queryClient = useQueryClient();

  const deleteUserVehicle = async (
    vehicleId: string | number,
  ): Promise<void> => {
    return await axios.delete(
      apiRoutes.vehicles.deleteVehicle.replace(':id', vehicleId.toString()),
    );
  };

  return useMutation<void, HttpError, number | string>({
    ...options,
    mutationFn: deleteUserVehicle,
    onSuccess: (...params) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.vehicles.userVehicles,
      });
      options?.onSuccess?.(...params);
    },
  });
};
