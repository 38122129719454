export const apiRoutes = {
  auth: {
    refreshToken: 'auth/refresh-token',
    email: {
      login: 'auth/email/login',
      register: 'auth/email/register',
      registrationConfirmation: 'auth/email/registration-confirmation',
      passwordChange: 'auth/email/public-password-change',
      passwordResetRequest: 'auth/email/public-password-reset-request',
      passwordReset: 'auth/email/password-reset',
    },
    google: {
      login: 'auth/google/login',
    },
  },
  translations: {
    list: 'translations',
    update: 'translations/:id',
  },
  languages: {
    locale: 'languages/locale/:locale',
    options: 'languages/options',
  },
  userTokens: {
    get: 'user-tokens/:token',
  },
  health: {
    version: 'health/version',
  },
  users: {
    me: 'users/me',
  },
  roles: {
    mappedAccess: 'roles/mapped-access',
  },
  assets: {
    uploadImage: 'assets/upload-image',
    uploadPrivateFile: 'assets/upload-private-file',
    uploadPrivateFiles: 'assets/upload-private-files',
    getAsset: 'assets/owned/:id',
    downloadFile: 'assets/download-file/:id',
    getDownloadableFile: 'assets/get-downloadable-file/:id',
  },
  brands: {
    favorite: 'brands/favorite',
    options: 'brands/options/public',
  },
  categories: {
    topNavigation: 'categories/top-navigation',
    all: 'categories/public/all',
    homepage: 'categories/homepage',
  },
  manufacturers: {
    main: 'manufacturers/main',
  },
  products: {
    getProduct: 'products/:slug/public',
    searchProducts: 'products/search',
    cartItems: 'products/cart',
    featuredProducts: 'products/featured',
    topProducts: 'products/top',
    toggleUserLikedProduct: 'products/liked-product/:id',
    getPaginatedUserLikedProducts: 'products/liked-products',
    getUserLikedProductsIds: 'products/liked-products-ids',
    removeAllLikedProducts: 'products/remove-liked-products',
    similarProducts: 'products/similar',
    newestProducts: 'products/newest',
    brandProducts: 'products/brand',
  },
  vehicleModels: {
    options: 'vehicle-models/options/public',
  },
  vehicles: {
    options: 'vehicles/options/public',
    addUserVehicle: 'vehicles/user-vehicles/:id',
    selectedVehicle: 'vehicles/:id/selected',
    sendFindVehicleHelpEmail: 'vehicles/find-vehicle-email',
    userVehicles: 'vehicles/user-vehicles',
    getVehiclesByIds: 'vehicles/ids',
    deleteVehicle: 'vehicles/user-vehicles/:id',
  },
  blogs: {
    get: 'blogs/public/:id',
    getRelatedBlogs: 'blogs/related/:id',
    getFeaturedBlogs: 'blogs/featured',
    getNewestBlogs: 'blogs/newest',
    getRecentlyReadBlogs: 'blogs/recently-read',
    getBlogsByCategory: '/blogs/public/category',
  },
  articleCategories: {
    get: 'article-categories/public',
    getArticleCategory: 'article-categories/:slug/public',
  },
  staticPages: {
    get: 'static-pages/name/:name',
    getAll: 'static-pages/all',
  },
  newsletter: {
    subscribe: 'newsletter',
  },
  order: {
    create: 'orders/public',
  },
  orderItems: {
    getUserOrderItems: 'order-items/me',
  },
  bankDetails: {
    getUser: 'bank-details/me',
    saveUser: 'bank-details/me',
  },
  vatRates: {
    public: 'vat-rates/public',
  },
  address: {
    getUserAddresses: 'address/me',
    createUserAddress: 'address/me',
    updateUserAddress: 'address/me/:id',
    deleteUserAddress: 'address/me/:id',
  },
  profile: {
    updateUserProfile: 'profile/update-self',
  },
  basket: {
    createOrUpdateUserBasket: 'baskets/:token',
  },
  faq: {
    getAll: 'faq/all',
    sendFaqHelpEmail: 'faq/help',
  },
};
