import { atom } from 'recoil';

export const selectedVehicleIdState = atom<number | null>({
  key: 'selectedVehicleIdState',
  default: null,
});

export const savedVehicleIdsState = atom<number[]>({
  key: 'savedVehicleIdsState',
  default: [],
});
