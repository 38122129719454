import React, { ReactNode } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import styles from './ButtonSelectInput.module.scss';
import { colors } from '../../../../config/styles/colors';
import cx from 'classnames';

export type SelectOption = {
  value: string;
  label: string;
  icon?: ReactNode;
};

export enum ButtonSelectInputVariant {
  'small' = 'small',
  'large' = 'large',
}

export type ButtonSelectInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  onChange?: (name: string, value: InputValueType) => void;
  className?: string;
  options?: SelectOption[];
  buttonVariant?: ButtonSelectInputVariant;
};

export const ButtonSelectInput = ({
  label,
  name,
  value,
  onChange,
  className,
  options = [],
  buttonVariant = ButtonSelectInputVariant.large,
}: ButtonSelectInputProps) => {
  return (
    <Box
      className={cx(
        styles.buttonSelectInputContainer,
        styles[buttonVariant],
        className,
      )}
    >
      {label && (
        <Typography variant="subtitle2" className={styles.label}>
          {label}
        </Typography>
      )}
      <Box className={styles.options}>
        {options.map((option, index) => (
          <Button
            key={`option-${index}`}
            onClick={() => onChange?.(name ?? '', option.value)}
            className={cx(styles.option, {
              [styles.selectedOption]: option.value === value,
            })}
            style={{
              color: colors.textColor,
              backgroundColor: colors.backgroundColor,
              borderColor:
                option.value === value
                  ? colors.textColor
                  : colors.inputBorderColor,
            }}
          >
            {option.icon}
            {option.label}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default ButtonSelectInput;
