import { useQuery } from '@tanstack/react-query';
import { SelectOption } from '../../app/common/Input/SelectInput/SelectInput';
import { apiRoutes } from '../../config/api/apiRoutes';
import axios from '../../libraries/axios/axios';
import { queryKeys } from '../keys';

export const useLanguageOptionsQuery = () => {
  const fetchLanguageOptions = async (): Promise<SelectOption[]> => {
    const { data } = await axios.get(apiRoutes.languages.options);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.languages.options,
    queryFn: () => fetchLanguageOptions(),
    initialData: [],
  });
};
