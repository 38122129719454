import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from './FooterItem.module.scss';
import { ReactSVG } from 'react-svg';
import { Typography } from '@mui/material';

export type FooterItemProps = {
  label: string;
  icon?: ReactNode;
  link?: string;
  onClick?: () => void;
};

export const FooterItem = ({ label, link, onClick, icon }: FooterItemProps) => {
  const ParentEl = onClick ? Typography : Link;

  return (
    <ParentEl className={styles.item} to={link ?? ''} onClick={onClick}>
      {typeof icon === 'string' ? (
        <ReactSVG src={icon} className={styles.icon} />
      ) : (
        icon
      )}
      {label}
    </ParentEl>
  );
};

export default FooterItem;
