import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../../libraries/axios/axios';
import { apiRoutes } from '../../config/api/apiRoutes';
import { HttpError } from '../../types/Api';
import { SubmitValues } from '../../app/hooks/useForm/useForm';
import { TokenModel } from '../../domain/Auth';
import { useSetRecoilState } from 'recoil';
import { authTokenState } from '../../atoms/auth/auth.atom';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../utils/localStorage/localStorage';

export const useEmailRegisterMutation = () => {
  const postEmailRegister = async (data: SubmitValues): Promise<void> => {
    return await axios.post(apiRoutes.auth.email.register, data);
  };

  return useMutation<void, HttpError, SubmitValues>({
    mutationFn: postEmailRegister,
  });
};

export const useEmailLoginMutation = () => {
  const setAuthStateValue = useSetRecoilState(authTokenState);

  const postEmailLogin = async (values: SubmitValues): Promise<TokenModel> => {
    const { data } = await axios.post(apiRoutes.auth.email.login, values);

    return data;
  };

  const handleSuccess = (successResponse: TokenModel) => {
    setAuthStateValue(successResponse);
    setLocalStorageValue(LocalStorageKey.token, successResponse);
  };

  return useMutation<TokenModel, HttpError, SubmitValues>({
    mutationFn: postEmailLogin,
    onSuccess: handleSuccess,
  });
};

export const usePasswordResetRequestMutation = () => {
  const postPasswordResetRequestMutation = async (
    data: SubmitValues,
  ): Promise<void> => {
    return await axios.post(apiRoutes.auth.email.passwordResetRequest, data);
  };

  return useMutation<void, HttpError, SubmitValues>({
    mutationFn: postPasswordResetRequestMutation,
  });
};

export const usePasswordResetMutation = () => {
  const postPasswordResetMutation = async (
    data: SubmitValues,
  ): Promise<void> => {
    return await axios.post(apiRoutes.auth.email.passwordReset, data);
  };

  return useMutation<void, HttpError, SubmitValues>({
    mutationFn: postPasswordResetMutation,
  });
};

export const useChangePasswordMutation = (
  options: UseMutationOptions<void, HttpError, SubmitValues>,
) => {
  const patchPasswordChange = async (data: SubmitValues): Promise<void> => {
    return await axios.patch(apiRoutes.auth.email.passwordChange, data);
  };

  return useMutation<void, HttpError, SubmitValues>({
    mutationFn: patchPasswordChange,
    ...options,
  });
};
