import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export const validateNumber = (
  value: InputValueType,
  translations: Translation[],
) => {
  if (!/^(-?\d+(\.\d+)?)?$/.test(value)) {
    return translate(translations, 'VALIDATION.INVALID_NUMBER');
  }

  return '';
};
