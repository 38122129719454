import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';
import { phone } from 'phone';

export const validatePhone = (
  value: InputValueType,
  translations: Translation[],
) => {
  if (!value || value.length === 0) {
    return '';
  }

  if (phone(value, { validateMobilePrefix: false }).isValid) {
    return '';
  }

  return translate(translations, 'VALIDATION.PHONE_NUMBER');
};
