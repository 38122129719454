import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import styles from './AvatarInput.module.scss';
import { InputValueType } from '../../../hooks/useForm/useForm';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useMutation } from '@tanstack/react-query';
import axios, { ErrorResponse } from '../../../../libraries/axios/axios';
import { apiRoutes } from '../../../../config/api/apiRoutes';
import { Asset } from '../../../../domain/Asset';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { colors } from '../../../../config/styles/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import cx from 'classnames';

export type AvatarInputProps = {
  name?: string;
  value?: InputValueType;
  onChange?: (name: string, value: InputValueType) => void;
  containerClassName?: string;
  className?: string;
  hideAllowedFiles?: boolean;
};

export const AvatarInput = ({
  name,
  onChange,
  value,
  containerClassName,
  className,
  hideAllowedFiles,
}: AvatarInputProps) => {
  const translations = useRecoilValue(translationsState);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

  const {
    mutate: getImage,
    data: imageResponse,
    isSuccess,
    isPending: getLoading,
  } = useMutation<{ data: Asset }, ErrorResponse>({
    mutationFn: () => {
      return axios.get(apiRoutes.assets.getAsset.replace(':id', value));
    },
  });

  const {
    mutate: upload,
    data: response,
    isPending: uploadLoading,
  } = useMutation<{ data: Asset }, ErrorResponse, File>({
    mutationFn: (image: File) => {
      const formData = new FormData();
      formData.append('image', image);

      return axios.post(apiRoutes.assets.uploadImage, formData);
    },
  });

  useEffect(() => {
    if (!value) {
      return;
    }

    if (!isSuccess) {
      getImage();
    }
  }, [value, isSuccess]);

  useEffect(() => {
    if (response?.data.url) {
      setAvatarUrl(response?.data.url);
      onChange?.(name ?? '', response?.data.id);
    }
  }, [response]);

  useEffect(() => {
    if (imageResponse?.data.url) {
      setAvatarUrl(imageResponse?.data.url);
    }
  }, [imageResponse]);

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<any>) => {
    const files = event.target.files;

    if (files.length === 0) {
      return;
    }

    upload(files[0]);
  };

  return (
    <Box className={cx(styles.avatarInputContainerOuter, containerClassName)}>
      <Box
        className={cx(styles.avatarInputContainer, className)}
        onClick={handleUploadCLick}
        style={{ borderColor: colors.inputBorderColor }}
      >
        <input
          ref={uploadInputRef}
          type={'file'}
          name={name}
          onChange={handleChange}
          accept=".jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp,.svg,.heif,.heic,.ico,.avif"
        />
        {(uploadLoading || getLoading) && (
          <div
            className={styles.loader}
            style={{ borderRightColor: colors.textColor }}
          />
        )}
        <Box className={styles.avatarInput}>
          {avatarUrl && (
            <Box component="span" className={styles.avatarInputInner}>
              <span className={styles.imageContainer}>
                <img src={avatarUrl} />
              </span>
            </Box>
          )}
          {!avatarUrl && (
            <Box component="span" className={styles.avatarInputInner}>
              <span className={styles.imageContainer}>
                <AccountCircleIcon style={{ color: colors.inputLabelColor }} />
              </span>
            </Box>
          )}
          <Box
            className={styles.placeholderContainer}
            style={{
              color: colors.avatarPlaceholderColor,
              backgroundColor: colors.avatarPlaceholderBackgroundColor,
            }}
          >
            <AddAPhotoIcon className={styles.photoIcon} />
            <span className={styles.updatePhoto}>
              {translate(translations, 'AVATAR_INPUT.UPDATE_PHOTO')}
            </span>
          </Box>
        </Box>
      </Box>
      {!hideAllowedFiles && (
        <Box
          className={styles.allowedFiles}
          style={{ color: colors.inputLabelColor }}
          dangerouslySetInnerHTML={{
            __html: translate(translations, 'AVATAR_INPUT.ALLOWED_FILES'),
          }}
        />
      )}
    </Box>
  );
};

export default AvatarInput;
