import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import styles from './SwitcherInput.module.scss';
import cx from 'classnames';
import { colors } from '../../../../config/styles/colors';

export type SwitcherInputProps = {
  label?: string;
  labelExplanation?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: InputValueType) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
};

export const SwitcherInput = ({
  label,
  labelExplanation,
  name,
  value,
  onChange,
  onBlur,
  autoFocus,
  className,
  labelPlacement,
}: SwitcherInputProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          name={name}
          onChange={(e) => onChange?.(name ?? '', e.target.checked)}
          onBlur={() => onBlur?.(name)}
          autoFocus={autoFocus}
          checked={!!value}
        />
      }
      className={cx(styles.switcherInputContainer, className)}
      labelPlacement={labelPlacement}
      label={
        <>
          {label && (
            <Typography variant="subtitle2" className={styles.label}>
              {label}
            </Typography>
          )}
          {labelExplanation && (
            <Typography
              variant="body2"
              className={styles.labelExplanation}
              style={{ color: colors.textSubtitleColor }}
            >
              {labelExplanation}
            </Typography>
          )}
        </>
      }
    />
  );
};

export default SwitcherInput;
