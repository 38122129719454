import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import 'react-quill/dist/quill.snow.css';
import 'swiper/css';
import ResizeObserver from 'resize-observer-polyfill';
import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_SENTRY_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

window.ResizeObserver = ResizeObserver;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

reportWebVitals();
