import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../config/api/apiRoutes';
import axios from '../../libraries/axios/axios';
import { queryKeys } from '../keys';
import { VehicleModelOption } from '../../domain/VehicleModel';
import { SelectedVehicle } from '../../domain/Vehicle';

export const useVehicleOptionsQuery = (vehicleModelId: number | null) => {
  const fetchVehicleModelOptions = async (
    vehicleModelId: number | null,
  ): Promise<VehicleModelOption[]> => {
    const { data } = await axios.get(apiRoutes.vehicles.options, {
      params: {
        vehicleModel: vehicleModelId,
      },
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.vehicles.byVehicleModel(vehicleModelId),
    queryFn: () => fetchVehicleModelOptions(vehicleModelId),
    enabled: !!vehicleModelId,
  });
};

export const useSelectedVehicleQuery = (vehicleId: number | null) => {
  const fetchSelectedVehicle = async (
    vehicleId: number | null,
  ): Promise<SelectedVehicle> => {
    const { data } = await axios.get(
      apiRoutes.vehicles.selectedVehicle.replace(
        ':id',
        vehicleId?.toString() ?? '',
      ),
    );
    return data;
  };

  return useQuery({
    queryKey: queryKeys.vehicles.bySelectedVehicle(vehicleId),
    queryFn: () => fetchSelectedVehicle(vehicleId),
    enabled: !!vehicleId,
  });
};

export const useUserVehiclesQuery = (enabled?: boolean) => {
  const fetchUserVehicles = async (): Promise<SelectedVehicle[]> => {
    const { data } = await axios.get(apiRoutes.vehicles.userVehicles);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.vehicles.userVehicles,
    queryFn: () => fetchUserVehicles(),
    enabled,
    initialData: [],
  });
};

export const useVehiclesByIdsQuery = (ids: number[], enabled?: boolean) => {
  const fetchVehiclesByIds = async (
    ids: number[],
  ): Promise<SelectedVehicle[]> => {
    const { data } = await axios.get(apiRoutes.vehicles.getVehiclesByIds, {
      params: { ids },
    });
    return data;
  };

  return useQuery({
    queryKey: queryKeys.vehicles.byIds(ids),
    queryFn: () => fetchVehiclesByIds(ids),
    enabled,
    initialData: [],
  });
};
