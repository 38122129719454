import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton as MuiIconButton,
} from '@mui/material';
import styles from './AttachmentBox.module.scss';
import { useMutation } from '@tanstack/react-query';
import axios, { ErrorResponse } from '../../../../../libraries/axios/axios';
import { apiRoutes } from '../../../../../config/api/apiRoutes';
import { FileIcon } from 'react-file-icon';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '../../../../../config/styles/colors';
import { saveAs } from 'file-saver';

export type FileContent = {
  contentType?: string;
  fileBuffer?: { data: number[] };
  extension: string;
  filename: string;
};

export type AttachmentBoxProps = {
  id: number;
  onRemove?: (id: number) => void;
};

export const AttachmentBox = ({ id, onRemove }: AttachmentBoxProps) => {
  const [fileContent, setFileContent] = useState<FileContent | null>(null);

  const { mutate: getFile, isPending } = useMutation<
    { data: FileContent },
    ErrorResponse
  >({
    mutationFn: () => {
      return axios.get(
        apiRoutes.assets.getDownloadableFile.replace(
          ':id',
          id?.toString() ?? '',
        ),
      );
    },
    onSuccess: (response) => {
      setFileContent(response?.data);
    },
  });

  const { mutate: downloadFile, isPending: pendingDownload } = useMutation<
    { data: FileContent },
    ErrorResponse
  >({
    mutationFn: () => {
      return axios.get(
        apiRoutes.assets.downloadFile.replace(':id', id?.toString() ?? ''),
      );
    },
    onSuccess: (response) => {
      const downloadFileContent = response.data;

      if (!downloadFileContent || !downloadFileContent.fileBuffer) {
        return;
      }

      saveAs(
        new Blob([new Uint8Array(downloadFileContent.fileBuffer.data)], {
          type: downloadFileContent.contentType,
        }),
        downloadFileContent.filename,
      );
    },
  });

  useEffect(() => {
    getFile();
  }, []);

  const fileImage = useMemo(() => {
    if (!fileContent) {
      return null;
    }

    if (
      fileContent.contentType?.startsWith('image/') &&
      fileContent.fileBuffer?.data
    ) {
      const uint8Array = new Uint8Array(fileContent.fileBuffer.data);
      const blob = new Blob([uint8Array], { type: fileContent.contentType });
      const file = new File([blob], fileContent.filename, {
        type: fileContent.contentType,
      });

      return <img src={URL.createObjectURL(file)} alt={fileContent.filename} />;
    }

    return <FileIcon extension={fileContent.extension} />;
  }, [fileContent]);

  const handleDownload = () => {
    if (fileContent?.fileBuffer) {
      saveAs(
        new Blob([new Uint8Array(fileContent.fileBuffer.data)], {
          type: fileContent.contentType,
        }),
        fileContent.filename,
      );
      return;
    }

    downloadFile();
  };

  return (
    <Box className={styles.attachmentBoxContainer}>
      <Box className={styles.innerContainer}>
        <Box
          className={styles.image}
          title={fileContent?.filename}
          onClick={handleDownload}
        >
          {fileImage}
        </Box>
        {(isPending || pendingDownload) && (
          <Box
            className={styles.loader}
            style={{
              backgroundColor: colors.loaderBackgroundColor,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {onRemove && (
          <MuiIconButton
            onClick={() => onRemove(id)}
            size="small"
            className={styles.closeIcon}
            style={{
              color: colors.backgroundColor,
              backgroundColor: colors.textSubtitleColor,
            }}
          >
            <CloseIcon fontSize={'small'} />
          </MuiIconButton>
        )}
      </Box>
      <Box
        className={styles.fileName}
        style={{ color: colors.textSubtitleColor }}
      >
        {fileContent?.filename.split('/').pop()}
      </Box>
    </Box>
  );
};

export default AttachmentBox;
