import React from 'react';
import { TextField } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import HelperText from '../../HelperText/HelperText';

export type TextAreaInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: string) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
};

export const TextAreaInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
}: TextAreaInputProps) => {
  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={(e) => onChange?.(name ?? '', e.target.value)}
      onBlur={() => onBlur?.(name)}
      helperText={errors && errors.length > 0 && <HelperText errors={errors} />}
      error={errors && errors.length > 0}
      autoFocus={autoFocus}
      className={className}
      rows={4}
      multiline
      type="text"
    />
  );
};

export default TextAreaInput;
