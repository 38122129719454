import React from 'react';
import { InputValueType } from '../../../hooks/useForm/useForm';
import {
  DatePicker,
  DateView,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import HelperText from '../../HelperText/HelperText';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/lt';
import { useRecoilValue } from 'recoil';
import { selectedLanguageState } from '../../../../atoms/language/language.atom';

export type DateInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: InputValueType) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
  format?: string;
  views?: DateView[];
  view?: DateView;
};

export const DateInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
  format,
  views,
  view,
}: DateInputProps) => {
  const selectedLanguage = useRecoilValue(selectedLanguageState);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={selectedLanguage.toLocaleLowerCase()}
    >
      <DatePicker
        name={name}
        label={label}
        className={className}
        autoFocus={autoFocus}
        value={value ? dayjs(value) : null}
        onClose={() => onBlur?.(name)}
        onChange={(value) => {
          onChange?.(name ?? '', value?.toDate());
        }}
        slotProps={{
          textField: {
            helperText: errors && errors.length > 0 && (
              <HelperText errors={errors} />
            ),
            error: errors && errors.length > 0,
          },
        }}
        format={format ?? 'YYYY-MM-DD'}
        view={view}
        views={views}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
