import React, { ChangeEvent, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import { useMutation } from '@tanstack/react-query';
import axios, { ErrorResponse } from '../../../../libraries/axios/axios';
import { apiRoutes } from '../../../../config/api/apiRoutes';
import { Asset } from '../../../../domain/Asset';
import { colors } from '../../../../config/styles/colors';
import styles from './AttachmentInput.module.scss';
import AttachmentBox from './AttachmentBox/AttachmentBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export type AttachmentInputProps = {
  name?: string;
  label?: string;
  value?: InputValueType;
  onChange?: (name: string, value: InputValueType) => void;
};

export const AttachmentInput = ({
  name,
  label,
  onChange,
  value,
}: AttachmentInputProps) => {
  const { mutate: upload, data: response } = useMutation<
    { data: Asset[] },
    ErrorResponse,
    File[]
  >({
    mutationFn: (files: File[]) => {
      const formData = new FormData();

      Array.from(files).forEach((file) => {
        formData.append('files', file);
      });

      return axios.post(apiRoutes.assets.uploadPrivateFiles, formData);
    },
  });

  useEffect(() => {
    if (response?.data) {
      onChange?.(name ?? '', [
        ...value,
        ...response?.data?.map((item) => item.id),
      ]);
    }
  }, [response]);

  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadCLick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const handleChange = (event: ChangeEvent<any>) => {
    const files = event.target.files;

    if (files.length === 0) {
      return;
    }

    upload(files);
  };

  const handleRemove = (id: number) => {
    onChange?.(
      name ?? '',
      value.filter((singleId: number) => singleId !== id),
    );
  };

  return (
    <Box className={styles.attachmentInputContainer}>
      {label && (
        <Typography variant="subtitle2" className={styles.label}>
          {label}
        </Typography>
      )}
      <Box className={styles.attachmentInputContainerOuter}>
        <Box className={styles.fileSelectContainer}>
          {value &&
            Array.isArray(value) &&
            value?.map((id: number) => (
              <AttachmentBox key={id} id={id} onRemove={handleRemove} />
            ))}
          <Box
            className={styles.fileUpload}
            style={{
              color: colors.inputLabelColor,
              backgroundColor: colors.buttonSoftBackgroundColor,
              borderColor: colors.inputBorderColor,
            }}
            onClick={handleUploadCLick}
          >
            <input
              ref={uploadInputRef}
              type={'file'}
              name={name}
              onChange={handleChange}
              multiple
            />
            <CloudUploadIcon />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AttachmentInput;
