import { atom } from 'recoil';
import { CartItem } from '../../domain/Cart';
import {
  getLocalStorageValue,
  LocalStorageKey,
} from '../../utils/localStorage/localStorage';

export const currentCartItemsState = atom<CartItem[]>({
  key: 'currentCartItemsState',
  default: getLocalStorageValue(LocalStorageKey.cart) ?? [],
});

export const currentBasketState = atom<string | null>({
  key: 'currentBasketState',
  default: getLocalStorageValue(LocalStorageKey.cart) ?? null,
});
