import React, { useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import styles from './BasketNavigation.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import leftActionIcon from '../../../assets/icons/left-action.svg';
import secureIcon from '../../../assets/icons/secure.svg';
import basketIcon from '../../../assets/icons/basket.svg';
import paymentIcon from '../../../assets/icons/payment.svg';
import logo from '../../../assets/logo.svg';
import { ReactSVG } from 'react-svg';
import { routes } from '../../../config/routes/routes';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { useCartProductsQuery } from '../../../api/products/queries';
import { currentCartItemsState } from '../../../atoms/cart/cart.atom';

export const BasketNavigation = () => {
  const translations = useRecoilValue(translationsState);
  const cartItems = useRecoilValue(currentCartItemsState);

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  const { data: products } = useCartProductsQuery({
    products: cartItems.map((cartItem) => cartItem.productId),
  });

  const handleTabChange = (_: any, value: number) => {
    setActiveTab(value);

    switch (value) {
      case 0:
        navigate(routes.checkout);
        break;
      case 1:
        navigate(routes.checkoutOrder);
        break;
      default:
        navigate(routes.checkout);
    }
  };

  useEffect(() => {
    if (location.pathname === routes.checkout) {
      setActiveTab(0);
    }

    if (location.pathname === routes.checkoutOrder) {
      setActiveTab(1);
    }
  }, [location.pathname]);

  const isDisabledCheckout = useMemo(() => {
    return !products || products.length === 0;
  }, [products]);

  return (
    <Box className={styles.basketNavigationContainer}>
      <Box className={styles.basketNavigation}>
        <Box className={styles.controlsContainer}>
          <Link to={routes.products} className={styles.backButton}>
            <img src={leftActionIcon} alt="Back to shop" />
            <Typography className={styles.backLabel}>
              {translate(translations, 'BASKET_NAVIGATION.BACK_BUTTON')}
            </Typography>
          </Link>
          <Link to={routes.homepage} className={styles.logo}>
            <img src={logo} alt="Logo" />
          </Link>
          <Box className={styles.secureLogin}>
            <img src={secureIcon} alt="Secure login" />
            <Typography className={styles.secureLabel}>
              {translate(translations, 'BASKET_NAVIGATION.SECURE_LOGIN')}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.tabItemsContainer}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab
              className={styles.tabItem}
              label={translate(
                translations,
                'BASKET_NAVIGATION.BASKET_TAB_LABEL',
              )}
              icon={<ReactSVG src={basketIcon} />}
            />
            <Tab
              className={styles.tabItem}
              label={translate(
                translations,
                'BASKET_NAVIGATION.ORDER_TAB_LABEL',
              )}
              icon={<ReactSVG src={paymentIcon} />}
              disabled={isDisabledCheckout}
            />
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default BasketNavigation;
