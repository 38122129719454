import React, { useState } from 'react';
import { Button } from '@mui/material';
import ConfirmationDialog from '../../Dialog/ConfirmationDialog/ConfirmationDialog';

export type ActionInputProps = {
  label?: string;
  type?: 'primary' | 'error';
  apiUrl?: string;
  successMessage?: string;
  confirmationMessage?: string;
};

export const ActionInput = ({
  type,
  label,
  apiUrl,
  successMessage,
  confirmationMessage,
}: ActionInputProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button color={type} onClick={() => setIsOpen(true)}>
        {label}
      </Button>
      <ConfirmationDialog
        title={label ?? ''}
        apiUrl={apiUrl ?? ''}
        description={confirmationMessage ?? ''}
        successMessage={successMessage ?? ''}
        onSuccess={() => setIsOpen(false)}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

export default ActionInput;
