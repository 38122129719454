export const routes = {
  homepage: '/',
  catalog: '/catalog',
  brandCatalog: '/catalog/:brand',
  vehicleCatalog: '/catalog/:brand/:vehicleModel/:vehicle',
  products: '/products',
  product: '/products/:slug',
  checkout: '/checkout',
  checkoutOrder: '/checkout/order',
  checkoutLogin: '/checkout/login',
  profile: {
    address: '/profile/address',
    bankDetails: '/profile/bank-details',
    favorites: '/profile/favorites',
    orders: '/profile/orders',
    settings: '/profile/settings',
    vehicles: '/profile/vehicles',
  },
  articles: {
    index: '/articles',
    categories: '/articles/categories',
    category: '/articles/categories/:slug',
    article: '/articles/:id',
  },
  auth: {
    passwordReset: '/',
  },
  staticPage: '/:slug',
};
