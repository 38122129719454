import { atom } from 'recoil';
import { TokenModel } from '../../domain/Auth';
import { MappedRoleAccess } from '../../domain/Role';

export const authTokenState = atom<TokenModel | null>({
  key: 'authTokenState',
  default: null,
});

export const roleAccessState = atom<MappedRoleAccess[]>({
  key: 'roleAccessState',
  default: [],
});

export const loginModalState = atom<boolean>({
  key: 'loginModalState',
  default: false,
});
