import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export const validateRequired = (
  value: InputValueType,
  translations: Translation[],
) => {
  if (!value) {
    return translate(translations, 'VALIDATION.REQUIRED');
  }

  if (value.length === 0) {
    return translate(translations, 'VALIDATION.REQUIRED');
  }

  return '';
};
