import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import { InputValueType } from '../../../../app/hooks/useForm/useForm';

export type MinValidator = {
  length: number;
};

export const validateMin = (
  value: InputValueType,
  rule: MinValidator,
  translations: Translation[],
) => {
  const valueNumber = Number(value);

  if (valueNumber < rule.length) {
    return translate(translations, 'VALIDATION.MIN').replace(
      ':length',
      rule.length.toString(),
    );
  }

  return '';
};
