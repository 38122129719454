import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import BasketNavigation from '../../components/BasketNavigation/BasketNavigation';
import Subscribe from '../../components/Subscribe/Subscribe';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../utils/localStorage/localStorage';
import { useRecoilValue } from 'recoil';
import { currentCartItemsState } from '../../../atoms/cart/cart.atom';

export const BasketLayout = () => {
  const cartItems = useRecoilValue(currentCartItemsState);

  useEffect(() => {
    setLocalStorageValue(LocalStorageKey.cart, cartItems);
  }, [cartItems]);

  return (
    <>
      <BasketNavigation />
      <Outlet />
      <Subscribe />
      <Footer />
    </>
  );
};

export default BasketLayout;
