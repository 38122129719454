import { Box, IconButton, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import styles from './ProfileSidebar.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import { ReactSVG } from 'react-svg';
import garageIcon from '../../../../assets/icons/garage.svg';
import addressIcon from '../../../../assets/icons/address.svg';
import orderIcon from '../../../../assets/icons/confirmation.svg';
import bankIcon from '../../../../assets/icons/bank.svg';
import settingsIcon from '../../../../assets/icons/settings.svg';
import favoriteIcon from '../../../../assets/icons/favorite-filled-small.svg';
import signoutIcon from '../../../../assets/icons/signout.svg';
import moreIcon from '../../../../assets/icons/more.svg';
import { useWindowWidth } from '@react-hook/window-size';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { currentUserState } from '../../../../atoms/user/user.atom';
import {
  clearLocalStorageValue,
  LocalStorageKey,
} from '../../../../utils/localStorage/localStorage';
import useResetState from '../../../hooks/useResetState/useResetState';
import AvatarInput from '../../../common/Input/AvatarInput/AvatarInput';
import { InputValueType } from '../../../hooks/useForm/useForm';
import { useUpdateUserProfileMutation } from '../../../../api/profile/mutations';
import { toast } from 'sonner';

const MOBILE_BREAKPOINT = 970;

const ProfileSidebar = () => {
  const translations = useRecoilValue(translationsState);
  const currentUser = useRecoilValue(currentUserState);
  const resetState = useResetState();
  const width = useWindowWidth();
  const navigate = useNavigate();
  const [isMore, setIsMore] = useState(false);

  const { mutate: updateProfilePicture } = useUpdateUserProfileMutation({
    onError: (error) =>
      toast.error(
        translate(
          translations,
          error?.response?.data?.message ?? 'Unknown error',
        ),
      ),
    onSuccess: () =>
      toast.success(
        translate(translations, 'PROFILE_SIDEBAR.UPDATE_AVATAR_SUCCESS'),
      ),
  });

  const isMobile = useMemo(() => width < MOBILE_BREAKPOINT, [width]);

  const profileImageId = useMemo(
    () => currentUser?.profile.image?.id,
    [currentUser?.profile.image?.id],
  );

  const handleImageChange = (_: any, value: InputValueType) => {
    updateProfilePicture({ id: currentUser?.profile.id!, image: value });
  };

  const handleLogout = () => {
    clearLocalStorageValue(LocalStorageKey.token);
    resetState();
    navigate(routes.homepage);
  };

  return (
    <Box className={styles.sidebar}>
      <Box className={styles.client}>
        <AvatarInput
          value={profileImageId}
          hideAllowedFiles
          containerClassName={styles.imageContainer}
          className={styles.image}
          onChange={handleImageChange}
        />
        <Box className={styles.info}>
          <Box className={styles.name}>
            <Typography>
              {currentUser?.profile.firstName} {currentUser?.profile.lastName}
            </Typography>
          </Box>
          {/* <Box className={styles.clientNumber}>
            <Typography>
              {translate(translations, 'PROFILE_SIDEBAR.CLIENT_NUMBER_TITLE')}
            </Typography>
            <Typography>1111</Typography>
          </Box> */}
          {/* <Box className={styles.balance}>
            <Typography>
              {translate(translations, 'PROFILE_SIDEBAR.BALANCE_TITLE')}
            </Typography>
            <Typography>9999,99 €</Typography>
          </Box> */}
        </Box>
      </Box>

      <Box className={styles.navigation}>
        <Link to={routes.profile.vehicles}>
          <ReactSVG src={garageIcon} />
          {translate(translations, 'PROFILE_SIDEBAR.MY_VEHICLES')}
        </Link>
        <Link to={routes.profile.orders}>
          <ReactSVG src={orderIcon} />
          {translate(translations, 'PROFILE_SIDEBAR.MY_ORDERS')}
        </Link>
        {!isMobile && (
          <>
            <Link to={routes.profile.address}>
              <ReactSVG src={addressIcon} />
              {translate(translations, 'PROFILE_SIDEBAR.MY_ADDRESSES')}
            </Link>
            <Link to={routes.profile.bankDetails}>
              <ReactSVG src={bankIcon} />
              {translate(translations, 'PROFILE_SIDEBAR.BANK_DETAILS')}
            </Link>
            <Link to={routes.profile.settings}>
              <ReactSVG src={settingsIcon} />
              {translate(translations, 'PROFILE_SIDEBAR.SETTINGS')}
            </Link>
          </>
        )}
        <Link to={routes.profile.favorites}>
          <ReactSVG src={favoriteIcon} />
          {translate(translations, 'PROFILE_SIDEBAR.FAVOURITES_LIST')}
        </Link>
        {!isMobile ? (
          <button className={styles.signoutBtn} onClick={handleLogout}>
            <ReactSVG src={signoutIcon} />
            {translate(translations, 'PROFILE_SIDEBAR.SIGN_OUT_BUTTON')}
          </button>
        ) : (
          <Box className={styles.moreContainer}>
            <button
              className={styles.moreBtn}
              onClick={() => setIsMore((prev) => !prev)}
            >
              <ReactSVG src={moreIcon} />{' '}
              {translate(translations, 'PROFILE_SIDEBAR.MORE_BUTTON')}
            </button>
            {isMore && (
              <Box className={styles.moreMenu}>
                <IconButton onClick={() => setIsMore((prev) => !prev)}>
                  <ReactSVG src={moreIcon} />
                </IconButton>
                <Link to={routes.profile.address}>
                  <ReactSVG src={addressIcon} />
                </Link>
                <Link to={routes.profile.bankDetails}>
                  <ReactSVG src={bankIcon} />
                </Link>
                <Link to={routes.profile.settings}>
                  <ReactSVG src={settingsIcon} />
                </Link>
                <IconButton onClick={handleLogout}>
                  <ReactSVG src={signoutIcon} />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProfileSidebar;
