import React, { useMemo, useRef, useState } from 'react';
import { InputValueType } from '../../../hooks/useForm/useForm';
import HelperText from '../../HelperText/HelperText';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Popover,
  List,
  MenuItem,
  ListItemText,
  Typography,
} from '@mui/material';
import styles from './PhoneInput.module.scss';
import { countries, getEmojiFlag, TCountryCode } from 'countries-list';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { phone } from 'phone';
import cx from 'classnames';
import parsePhoneNumber from 'libphonenumber-js';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { colors } from '../../../../config/styles/colors';

export type PhoneInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: string) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
  required?: boolean;
  labelExplanation?: string;
};

export const PhoneInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
  required,
  labelExplanation,
}: PhoneInputProps) => {
  const translations = useRecoilValue(translationsState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef();
  const searchInputRef = useRef();

  const options = useMemo(() => {
    return Object.entries(countries).map(([key, value]) => ({
      value: key,
      label: value.native,
      labelInt: value.name,
      flag: getEmojiFlag(key as TCountryCode),
      phone: _.first(value.phone)?.toString() ?? '',
    }));
  }, []);

  const valueOption = useMemo(() => {
    return (
      options.find((selectOption) => {
        const validatedPhone = phone(value);

        if (validatedPhone.isValid) {
          return (
            selectOption.value.toString().toLowerCase() ===
            validatedPhone.countryIso2?.toLowerCase()
          );
        }

        return selectOption.phone
          .toString()
          .includes(value.toString().replace('+', ''));
      }) ?? _.first(options)
    );
  }, [options, value]);

  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) =>
        option.label.toLowerCase().includes(searchValue) ||
        option.labelInt.toLowerCase().includes(searchValue) ||
        option.phone.toLowerCase().includes(searchValue),
    );
  }, [options, searchValue]);

  const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setIsOpen(true);

    setTimeout(() => {
      // @ts-ignore
      searchInputRef.current?.focus();
    }, 100);
  };

  const handleClosePopover = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleCountryClick = (value: string) => {
    onChange?.(name ?? '', `+${value}`);
    handleClosePopover();

    setTimeout(() => {
      // @ts-ignore
      inputRef.current?.focus();
    }, 100);
  };

  return (
    <Box className={cx(styles.phoneInputContainer, className)}>
      <Button
        type="button"
        className={styles.inputButton}
        disableTouchRipple
        onClick={handleOpenPopover}
      >
        <Box
          component="span"
          className={styles.selectedPhone}
          style={{ color: colors.inputLabelColor }}
        >
          +{valueOption?.phone}
        </Box>
      </Button>
      <TextField
        label={label}
        name={name}
        value={parsePhoneNumber(value)?.formatInternational() ?? value}
        onChange={(e) => onChange?.(name ?? '', e.target.value)}
        onBlur={() => onBlur?.(name)}
        helperText={
          errors && errors.length > 0 && <HelperText errors={errors} />
        }
        inputRef={inputRef}
        type="tel"
        error={errors && errors.length > 0}
        autoFocus={autoFocus}
        className={styles.inputContainer}
        required={required}
      />
      {labelExplanation && (
        <Typography
          variant="body2"
          className={styles.inputInfo}
          style={{ color: colors.textSubtitleColor }}
        >
          {labelExplanation}
        </Typography>
      )}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: styles.countriesPopover }}
      >
        <Box className={styles.searchContainer}>
          <TextField
            name={name}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onBlur={() => onBlur?.(name)}
            placeholder={translate(translations, 'PHONE_INPUT.SEARCH')}
            className={styles.searchInput}
            inputRef={searchInputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={styles.endAdornment}
                >
                  <SearchIcon fontSize={'small'} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className={styles.countriesList}>
          <List className={styles.listElement}>
            {filteredOptions.map((option) => (
              <MenuItem
                key={option.value}
                className={styles.itemContainer}
                onClick={() => handleCountryClick(option.phone)}
              >
                <Box
                  component="span"
                  className={styles.itemFlag}
                  style={{ backgroundColor: colors.countryBackgroundColor }}
                >
                  {option.flag}
                </Box>
                <ListItemText className={styles.countryNameContainer}>
                  <Typography variant="body1" className={styles.countryName}>
                    {option.label}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={styles.countryValue}
                    style={{ color: colors.textSubtitleColor }}
                  >
                    {option.value} (+{option.phone})
                  </Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </List>
        </Box>
      </Popover>
    </Box>
  );
};

export default PhoneInput;
