import { Translation } from '../../../../domain/Translation';
import { translate } from '../../../translation/translation';
import {
  InputValue,
  InputValueType,
} from '../../../../app/hooks/useForm/useForm';

export type MatchingPasswordsValidator = {
  name: string;
};

export const validateMatchingPasswords = (
  value: InputValueType,
  values: InputValue[],
  rule: MatchingPasswordsValidator,
  translations: Translation[],
): string => {
  if (values.find((value) => value.name === rule.name)?.value !== value) {
    return translate(translations, 'VALIDATION.PASSWORDS_DO_NOT_MATCH');
  }

  return '';
};
