import React from 'react';
import Section from '../Section/Section';
import styles from './FeaturedBlog.module.scss';
import ArticleCard from '../ArticleCard/ArticleCard';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { useFeaturedBlogsQuery } from '../../../api/blogs/queries';
import { Box, CircularProgress } from '@mui/material';

const FeaturedBlogs = () => {
  const translations = useRecoilValue(translationsState);
  const { data: featuredBlogs, isLoading } = useFeaturedBlogsQuery();

  return (
    <Section
      title={translate(translations, 'FEATURED_BLOGS.TITLE')}
      wrapperClassName={styles.section}
      className={styles.featuredBlogs}
      accent
    >
      {isLoading && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading &&
        featuredBlogs.map((blog) => <ArticleCard key={blog.id} blog={blog} />)}
    </Section>
  );
};

export default FeaturedBlogs;
