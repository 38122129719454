import React, { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styles from './Footer.module.scss';
import FooterColumn, { FooterColumnProps } from './FooterColumn/FooterColumn';
import logo from '../../../assets/logo.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';
import phoneIcon from '../../../assets/icons/phone.svg';
import mailIcon from '../../../assets/icons/mail.svg';
import ainisIcon from '../../../assets/icons/ainis.svg';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { useTopProductsQuery } from '../../../api/products/queries';
import { useTopNavigationCategoriesQuery } from '../../../api/categories/queries';
import { staticPagesState } from '../../../atoms/static-page/static-pages.atom';
import { StaticPageType } from '../../../domain/StaticPage';
import { selectedLanguageState } from '../../../atoms/language/language.atom';
import {
  LocalStorageKey,
  setLocalStorageValue,
} from '../../../utils/localStorage/localStorage';
import { useLanguageOptionsQuery } from '../../../api/languages/queries';
import {
  countries,
  getEmojiFlag,
  languages,
  TCountryCode,
  TLanguageCode,
} from 'countries-list';
import _ from 'lodash';

export const Footer = () => {
  const translations = useRecoilValue(translationsState);
  const staticPages = useRecoilValue(staticPagesState);
  const setSelectedLanguage = useSetRecoilState(selectedLanguageState);

  const { data: topProducts } = useTopProductsQuery();
  const { data: categories } = useTopNavigationCategoriesQuery({});
  const { data: languageOptions } = useLanguageOptionsQuery();

  const columns: FooterColumnProps[][] = useMemo(() => {
    return [
      [
        {
          title: translate(translations, 'FOOTER.TOP_PRODUCTS_SECTION'),
          items:
            topProducts?.map((product) => ({
              label: product.name,
              link: routes.product.replace(':slug', product.seo?.slug ?? ''),
            })) ?? [],
        },
      ],
      [
        {
          title: translate(translations, 'FOOTER.CATEGORIES_SECTION'),
          items: categories.map((category) => ({
            label: category.name,
            link: `${routes.products}?category=${category.id}`,
          })),
        },
      ],
      [
        {
          title: translate(translations, 'FOOTER.ABOUT_SECTION'),
          items: [
            {
              label: translate(translations, 'FOOTER.ABOUT_US'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find(
                  (page) => page.name === StaticPageType.ABOUT_US,
                )?.seo?.slug ?? '',
              ),
            },
            {
              label: translate(translations, 'FOOTER.COMPANY_INFO'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find(
                  (page) => page.name === StaticPageType.COMPANY_INFO,
                )?.seo?.slug ?? '',
              ),
            },
            { label: 'Blog', link: routes.articles.index },
          ],
        },
      ],
      [
        {
          title: translate(translations, 'FOOTER.CUSTOMER_SERVICE_SECTION'),
          items: [
            {
              label: translate(translations, 'FOOTER.PAYMENT'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find((page) => page.name === StaticPageType.PAYMENT)
                  ?.seo?.slug ?? '',
              ),
            },
            {
              label: translate(translations, 'FOOTER.DELIVERY'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find(
                  (page) => page.name === StaticPageType.DELIVERY,
                )?.seo?.slug ?? '',
              ),
            },
            {
              label: translate(translations, 'FOOTER.RETURNS_AND_REFUNDS'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find(
                  (page) => page.name === StaticPageType.RETURN_AND_REFUNDS,
                )?.seo?.slug ?? '',
              ),
            },
          ],
        },
      ],
      [
        {
          title: translate(translations, 'FOOTER.HELP_AND_SUPPORT_SECTION'),
          items: [
            {
              label: translate(translations, 'FOOTER.PRIVACY_POLICY'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find(
                  (page) => page.name === StaticPageType.PRIVACY_POLICY,
                )?.seo?.slug ?? '',
              ),
            },
            {
              label: translate(translations, 'FOOTER.TERMS_AND_CONDITIONS'),
              link: routes.staticPage.replace(
                ':slug',
                staticPages.find(
                  (page) => page.name === StaticPageType.TERMS_AND_CONDITIONS,
                )?.seo?.slug ?? '',
              ),
            },
          ],
        },
      ],
    ];
  }, [topProducts]);

  const companyInfoColumns: FooterColumnProps[] = useMemo(() => {
    const mappedLanguages = languageOptions.map((option) => {
      const country = Object.keys(countries).find(
        (countryKey) => countryKey.toLowerCase() === option.label.toLowerCase(),
      );
      const language = Object.keys(languages).find(
        (languageKey) =>
          languageKey === _.first(countries[country as TCountryCode].languages),
      );

      return {
        label: languages[language as TLanguageCode]?.native,
        icon: (
          <Box className={styles.flag}>
            {getEmojiFlag(option.label as TCountryCode)}
          </Box>
        ),
        onClick: () => {
          setSelectedLanguage(option.label);
          setLocalStorageValue(LocalStorageKey.language, option.label);
        },
      };
    });

    return [
      {
        title: translate(translations, 'FOOTER.CONTACTS_TITLE'),
        items: [
          {
            label: translate(translations, 'FOOTER.CONTACTS_PHONE'),
            link: `tel:${translate(translations, 'FOOTER.CONTACTS_PHONE')}`,
            icon: phoneIcon,
          },
          {
            label: translate(translations, 'FOOTER.CONTACTS_EMAIL'),
            link: `mailto:${translate(translations, 'FOOTER.CONTACTS_EMAIL')}`,
            icon: mailIcon,
          },
        ],
      },
      {
        title: translate(translations, 'FOOTER.HOURS_TITLE'),
        items: [
          {
            label: translate(translations, 'FOOTER.HOURS_WORKDAYS'),
            link: '',
          },
          {
            label: translate(translations, 'FOOTER.HOURS_WEEKENDS'),
            link: '',
          },
        ],
      },
      {
        title: translate(translations, 'FOOTER.LANGUAGES_TITLE'),
        items: mappedLanguages,
      },
    ];
  }, [languageOptions]);

  return (
    <>
      <Box className={styles.footerContainer}>
        <Box className={styles.innerContainer}>
          <Box className={styles.columnsContainer}>
            {columns.map((columnItems, i) => (
              <Box key={`column-item-${i}`} className={styles.columnContainer}>
                {columnItems.map((column, j) => (
                  <FooterColumn
                    key={`column-${j}`}
                    title={column.title}
                    items={column.items}
                  />
                ))}
              </Box>
            ))}
          </Box>
          <Divider className={styles.divider} />
          <Box className={styles.companyInfoContainer}>
            <Box className={styles.companyInfo}>
              <Link to={routes.homepage}>
                <img src={logo} className={styles.logo} />
              </Link>
              <Typography className={styles.companyShortInfo}>
                {translate(translations, 'FOOTER.ABOUT_COMPANY')}
              </Typography>
            </Box>
            <Box className={styles.companyColumns}>
              {companyInfoColumns.map((column, index) => (
                <FooterColumn
                  key={`column-${index}`}
                  title={column.title}
                  items={column.items}
                />
              ))}
            </Box>
          </Box>
          <Box className={styles.copyrightContainer}>
            <Typography className={styles.copyright}>
              {translate(translations, 'FOOTER.COPYRIGHT')}
            </Typography>
            <Typography className={styles.copyright}>
              Made by Ainis.Space <img src={ainisIcon} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
