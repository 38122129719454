import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './TopNavigationIconItem.module.scss';
import { ReactSVG } from 'react-svg';

export type TopNavigationIconItemProps = {
  icon: string;
  title: string;
  showTitle?: boolean;
  subtitle: string;
  showSubtitle?: boolean;
  total?: number;
  onClick?: () => void;
};

export const TopNavigationIconItem = ({
  icon,
  title,
  showTitle = true,
  subtitle,
  showSubtitle = true,
  total,
  onClick,
}: TopNavigationIconItemProps) => {
  const totalValue = useMemo(() => {
    if (!total) {
      return null;
    }

    if (total < 100) {
      return total;
    }

    return '99+';
  }, [total]);

  return (
    <Box className={styles.topNavigationIconItem} onClick={onClick}>
      <Box className={styles.icon}>
        <ReactSVG src={icon} />
        {total ? <Box className={styles.badge}>{totalValue}</Box> : null}
      </Box>
      <Box className={styles.textContainer}>
        {showTitle && <Typography className={styles.title}>{title}</Typography>}
        {showSubtitle && (
          <Typography className={styles.subtitle}>{subtitle}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default TopNavigationIconItem;
