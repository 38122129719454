import React from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import HelperText from '../../HelperText/HelperText';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../../atoms/layout/layout.atom';
import { colors } from '../../../../config/styles/colors';

export type CurrencyInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: string) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
};

export const CurrencyInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
}: CurrencyInputProps) => {
  const currencyValue = useRecoilValue(currencyState);

  return (
    <TextField
      label={label}
      name={name}
      value={value}
      onChange={(e) => onChange?.(name ?? '', e.target.value)}
      onBlur={() => onBlur?.(name)}
      helperText={errors && errors.length > 0 && <HelperText errors={errors} />}
      error={errors && errors.length > 0}
      autoFocus={autoFocus}
      className={className}
      type="text"
      placeholder="0.00"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box style={{ color: colors.inputLabelColor }}>{currencyValue}</Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CurrencyInput;
