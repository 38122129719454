import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../config/api/apiRoutes';
import axios from '../../libraries/axios/axios';
import { queryKeys } from '../keys';
import { Manufacturer } from '../../domain/Manufacturer';

export const useMainManufacturersQuery = () => {
  const fetchMainManufacturers = async (): Promise<Manufacturer[]> => {
    const { data } = await axios.get(apiRoutes.manufacturers.main);
    return data;
  };

  return useQuery({
    queryKey: queryKeys.manufacturers.main,
    queryFn: () => fetchMainManufacturers(),
    initialData: [],
  });
};
