import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './ManufacturerCard.module.scss';
import { Asset } from '../../../../domain/Asset';

export type ManufacturerCardProps = {
  image?: Asset | null;
  title: string;
};

export const ManufacturerCard = ({ title, image }: ManufacturerCardProps) => {
  return (
    <Box className={styles.manufacturerCardContainer}>
      {image ? (
        <img className={styles.image} src={image.url} />
      ) : (
        <Typography className={styles.title}>{title}</Typography>
      )}
    </Box>
  );
};

export default ManufacturerCard;
