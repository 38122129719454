import React, { SyntheticEvent, useMemo } from 'react';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import _ from 'lodash';
import { countries, getEmojiFlag, TCountryCode } from 'countries-list';
import styles from './CountryInput.module.scss';
import HelperText from '../../HelperText/HelperText';
import { colors } from '../../../../config/styles/colors';

export type CountrySelectOption = {
  value: string;
  label: string;
  flag?: string;
};

export type CountryInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: InputValueType) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
  isMultiple?: boolean;
};

export const CountryInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
  isMultiple,
}: CountryInputProps) => {
  const options = useMemo(() => {
    return Object.entries(countries).map(([key, value]) => ({
      value: key,
      label: value.native,
      labelInt: value.name,
      flag: getEmojiFlag(key as TCountryCode),
    }));
  }, []);

  const valueOption = useMemo(() => {
    if (isMultiple) {
      return (
        options.filter((selectOption) =>
          value?.includes(selectOption.value.toString()),
        ) ?? []
      );
    }

    return (
      options.find((selectOption) => selectOption.value.toString() === value) ??
      null
    );
  }, [options, value, isMultiple]);

  const handleOnChange = (
    event: SyntheticEvent,
    changedValue:
      | CountrySelectOption
      | CountrySelectOption[]
      | (CountrySelectOption | CountrySelectOption[])[]
      | null,
  ) => {
    if (_.isArray(changedValue)) {
      onChange?.(
        name ?? '',
        changedValue.map((selectOption) => {
          return _.isArray(selectOption)
            ? selectOption.toString()
            : selectOption.value.toString();
        }),
      );
      return;
    }

    onChange?.(name ?? '', changedValue?.value.toString() ?? '');
  };

  return (
    <Autocomplete
      id="country-input"
      options={options}
      value={valueOption}
      className={className}
      autoFocus={autoFocus}
      onBlur={() => onBlur?.(name ?? '')}
      multiple={isMultiple}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          helperText={
            errors && errors.length > 0 && <HelperText errors={errors} />
          }
          error={errors && errors.length > 0}
          InputProps={{
            ...params.InputProps,
            startAdornment:
              !_.isArray(valueOption) && valueOption?.flag ? (
                <InputAdornment
                  position="start"
                  className={styles.selectedFlag}
                  style={{ color: colors.countryFlagColor }}
                >
                  {valueOption?.flag}
                </InputAdornment>
              ) : null,
          }}
        />
      )}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) => {
          const searchValue = inputValue.toLowerCase();
          return (
            option.label.toLowerCase().includes(searchValue) ||
            option.labelInt.toLowerCase().includes(searchValue)
          );
        })
      }
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props}>
          <span style={{ marginRight: 8 }}>{option.flag}</span>
          {option.label}
        </li>
      )}
      onChange={handleOnChange}
    />
  );
};

export default CountryInput;
