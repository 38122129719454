import React from 'react';
import Section from '../Section/Section';
import ManufacturerCard from './ManufacturerCard/ManufacturerCard';
import styles from './Manufacturers.module.scss';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { useMainManufacturersQuery } from '../../../api/manufacturers/queries';

export const Manufacturers = () => {
  const translations = useRecoilValue(translationsState);
  const { data: manufacturers } = useMainManufacturersQuery();

  return (
    <Section
      title={translate(translations, 'MANUFACTURERS.TITLE')}
      className={styles.cardsContainer}
      titleClassName={styles.title}
    >
      {manufacturers.map((manufacturer) => (
        <ManufacturerCard
          key={manufacturer.id}
          title={manufacturer.name}
          image={manufacturer.image}
        />
      ))}
    </Section>
  );
};

export default Manufacturers;
